@import url('https://fonts.googleapis.com/css2?family=Mulish');

.Title {
    position: absolute;

    height: 38px;
    left: 133px;
    top: 82px;

    display: flex;
    align-items: center;
}

.Title > .ico {
    position: absolute;
}

.Title H3 {
    margin-left: 30px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    color: var(--mainColor);
}

.TitleManageActivity {
    position: absolute;
    height: 38px;
    left: 143px;
    top: 82px;
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    min-width: 1080px;
    width: 88%;
}

.TitleManageActivity > .ico {
    position: absolute;
}

.TitleManageActivity H3 {
    margin-left: 30px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--mainColor);
}

.pointerCursor {
  cursor: pointer;
}

.activity {    
    
    position: absolute;
    width: 88%;
    overflow: hidden;
    left: 135px;
    top: 250px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;    

    word-wrap: break-word;
    overflow: auto;

    gap: 16px;
}

.activityAction {

    position: absolute;
    min-width: 1080px;
    width: 88%;
    height: 100px;
    left: 135px;
    top: 136px;

    /* WHITE */
    background: var(--white);
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 48px;
    box-sizing: border-box;
    
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    /* Third color */
    color: #748996;
}


.itemActivityAction{    
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;

    cursor: pointer;

    height: 40px;
    border-radius: 6px;
}

.columnCounter {
    margin-left: auto;
    margin-right: 24px;
}