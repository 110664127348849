@import "../../assets/styles/index.scss";

.MuiTypography-h6 {
  font-family: $FontFamily2 !important;
  font-size: $FontSize20 !important;
  line-height: $LineHeight32 !important;
  letter-spacing: $LetterSpacing015 !important;
  font-weight: bold !important;
}

.borderBottomTitleDialog {
  border-bottom: 1px solid $Gray3;
}

.widthAndHeigthDialog {
  width: auto;
  height: auto;
}

.headerDialog {
  display: flex;
  justify-content: space-between;
}

.iconCloseDialog {
  cursor: pointer;
  color: $Gray1;
  font-size: 2px;
}

.contentModal {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-top: 10%;
}

.bgAlertIcon {
  display: flex;
  align-items: center;
  align-content: center;
  background-color: $Warning100;
  border-radius: 50%;
}

.alertIcon {
  color: $Warning800;
  width: $FontSize16;
  width: 50px;
}

.bgCheckIcon {
  display: flex;
  align-items: center;
  align-content: center;
  background-color: $Success100;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.checkIcon {
  color: $Success800;
  width: $FontSize16;
  width: 50px;
}

.bgErrorIcon {
  display: flex;
  align-items: center;
  align-content: center;
  background-color: $Error100;
  border-radius: 50%;
}

.xIcon {
  color: $Error800;
  width: $FontSize16;
  width: 50px;
}

.iconModal {
  // margin-right: 1%;
  display: flex !important;
  align-items: center;
}

.titleModal {
  display: flex;
  align-items: center;
  align-content: center;
  font-family: $FontFamily3 !important;
  font-size: $FontSize16 !important;
  line-height: $LineHeight24 !important;
  letter-spacing: $LetterSpacing02 !important;
  color: black;
}

.descriptionModal {
  display: flex;
  align-items: center;
  align-content: center;
  font-family: $FontFamily3 !important;
  font-size: $FontSize14 !important;
  line-height: $LineHeight22 !important;
  letter-spacing: $LetterSpacing02 !important;
  color: $BlackHalf;
  margin-left: 8%;
}