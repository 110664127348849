@import "../../assets/styles/index.scss";


.textInputWidth {
  width: 60% !important;
  margin-bottom: 10px !important;
}


@media (max-width: 769px) {
  .textInputWidth {
    width: 90% !important;
  }
}

.MuiInputBase-input.Mui-disabled {
    background-color: $Gray3 !important;
}