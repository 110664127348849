@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.contentUhooPreview {
    background-image: url("../../../assets/images/UhooPreview.png");
    background-color: var(--white);
    min-height: 720px; /* 720 */
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 15px;
    margin: auto;
    vertical-align: middle;
    clip-path: inset(0 0 50px 0);    
}

.contentUhooPreviewAura {
    background-image: url("../../../assets/images/AuraPreview.png");
    background-color: var(--white);
    min-height: 720px; /* 720 */
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 15px;
    margin: auto;
    vertical-align: middle;
    clip-path: inset(0 0 50px 0);       
}

.uhoo {
    overflow: hidden;

    position: inherit;
    margin: auto;

    top: 90px;

    height: 427px;    
    width: 50%;
}

.uhooContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    
    position:absolute;
    vertical-align: middle;
    width: 100%;
    top: 45px;
}

.Uhoo {
    background-color: var(--white);    
    border-radius: 5px;

    word-wrap: break-word;
    height:auto;
     
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.titleUhoo {
    top: 0px;
}

.titleTextUhoo {
    padding-left: 5%;
    padding-right: 5%;

    word-wrap: break-word;

    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.textUhoo {
    word-wrap: break-word;
    height:auto;

    padding: 10px;

    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    color: black;
}

.contentImage {
    display: flex;
    justify-content: center;
}

.imageUhoo {
    width: 208px;    
}

.uhooMediaActions {

    word-wrap: break-word;

    width: 100%;
    height: 50px;

    border-radius: 4px;

    background-image: url("../../../assets/images/commentPost.png");
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

