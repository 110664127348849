.modalContainer {
  position: relative;
  width: 760px;
  height: auto;
  display: flex;
  justify-content: center;
  background: #fafafb;
  border-radius: 15px;
  flex-direction: column;
}

.modalTitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--mainColor);
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  justify-content: center;
}

.modalClose {
  position: absolute;

  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  right: 56px;
  left: 664px;
  top: 19px;

  background: rgba(196, 202, 206, 0.2);
  border-radius: 5px;
}

.modalContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  height: 70vh;
}

.alignRight {
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;
}

.modalAction {
  width: 135px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff8614;
  border-radius: 6px;
  margin: 10px 0px;
}

.modalActionText {
  /* H5/H5 */
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* BACKGROUND */
  color: #fafafb;
}

.fromLibraryPagination {
  position: relative;
  width: 135px;
  bottom: 20px;
  left: 41%;
}

.addLink {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}