.containerCardRecord {

    width: 240px;
    height: 128px;

    background: #F5F5FD;
    mix-blend-mode: normal;
    border-radius: 15px;

}

.titleCardRecord {
    height: 33px;
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    align-items: center;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */


    /* MAIN COLOR */

    color: var(--mainColor);

}

.actionCardRecord {  
    cursor: pointer; 
    margin-left: auto;
    justify-self: right;    
}

.dividerCardRecord {
    background-color: #EFF1F8;
    border: 1px solid #EFF1F8;
    box-sizing: border-box;

    margin: 0
}

.contentCardRecord {

    height: 95px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    /* Third color */
    color: #748996;

}