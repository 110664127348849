@import "../assets/styles/index.scss";

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}


.react-tabs__tab-list {  
  margin: 0 0 0 0;
  padding: 0;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  z-index: 50
}

.react-tabs__tab {
  display: inline-block;
  height: 22px;
  border-bottom: none;
  position: relative;
  list-style: none;
  padding: 13px 20px;
  cursor: pointer; 
  color: var(--thirdColor);
}

.react-tabs__tab--selected {
  color: var(--fourthColor);
  background: var(--white);
  border-radius: 15px 15px 0px 0px;
  // border: 1px solid;
  z-index: 10;
}

.react-tabs__tab--selected::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--white);
}

.react-tabs__tab--disabled {
  color: var(--thirdColor);
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  /*height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;*/
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.MuiTableRow-head {
  background-color: white;
}

.MuiTableCell-head {
  padding: 10px !important;
  font-family: 'Mulish' !important;
  font-style: normal !important;
  font-size: $FontSize16 !important;
  line-height: 40px !important;
  color: #748996 !important;
  border: 1px none !important;
  text-align: center !important;
}

.MuiTableCell-body {
  font-size: $FontSize14 !important;
  line-height: $LineHeight22 !important;
  letter-spacing: $LetterSpacing02 !important;
  padding: 10px !important;
  font-family: 'Mulish' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #183E55 !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.MuiTableContainer-root {
  margin-bottom: 20px;
}

.MuiTableCell-head {
  border: 1px solid $WhiteHalf;
  text-align: center !important;
}

.MuiTableRow-head {
  border: 1px solid white !important;

}

.MuiTableRow-root {
  border: 1px solid $WhiteHalf;
  margin: 10px;
  border-radius: 8px;
}

tr > .MuiTableCell-body {
  border-bottom: 1px solid $WhiteHalf !important;
  text-align: center !important;
}

.contentEmailSelect {
  height: 20px;
  margin-top: 10px;
  margin-left: 30px;
  font-size: $FontSize14;
  line-height: 20px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  color: #183E55;
}

.componentSpaceTable {
//  padding: 15px;
  padding: 15px;
  height: inherit;
}

.componentSpaceTableEmail {
    padding: 25px 15px;
    height: inherit;
  }

