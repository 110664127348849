.containerTableUsers {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#users_table {
    font-family: 'Mulish';
    border-collapse: collapse;
    width: 94%;
    border-radius: 6px;
    background: white;
    height: 60vh;
    overflow-y: scroll;
    margin: 0 auto;
}

#users_table {
    box-shadow: 0 4px 8px 0 rgba(10, 17, 40, 0.1);
    text-align: center;
}

.containerTableHeader, .containerBodyInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #f2f2f2;
}

.containerBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.containerTableHeader > p, .containerBodyInfo > p {
    height: 18px;
    min-width: 250px;
    margin: 0px;
    padding: 15px 10px;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Mulish';
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerTableHeader > h5, .containerBodyInfo > h5 {
  height: 18px;
  min-width: 130px;
  margin: 0px;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Mulish';
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

// #users_table tr:nth-child(even){background-color: #f2f2f2;}
.containerBody > p:hover {background-color: #f2f2f2;}

.containerTableHeader > h5, .containerTableHeader > p {
    color: var(--thirdColor);
}

.containerBodyInfo > h5, .containerBodyInfo > p {
    // border-top: 1px solid #f2f2f2;
    color: var(--mainColor);
}

.hideText {
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap; 
    justify-content: flex-start !important;
}

.containerBodyInfo > p > img, .containerBodyInfo > h5 > img {
   width: 35px;
   height: 35px;
   object-fit: cover;
   border-radius: 6px;
}

.imageLetter {
    background: #ffc3ba;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    font-size: 22px;
    line-height: 38px;
    color: #b36256;
    align-items: center;
  }


.gridIco {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.pincelIco {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tooltipWithFilter .tooltiptextGrid {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 16px;
  background-color: #00a4bd;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 0.889vw;
  line-height: 1.125rem;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  z-index: 100;
  right: 9%;
  top: 7px;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  }
  
.tooltipWithFilter .tooltiptextGrid::after {
  content: "";
  position: absolute;
  top: 12px;
  right: -12px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #00a4bd;
}

.tooltipWithFilter:hover .tooltiptextGrid {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptextGrid {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 16px;
  background-color: #00a4bd;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 0.889vw;
  line-height: 1.125rem;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  z-index: 100;
  right: 7%;
  top: 7px;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  }
  
  .tooltip .tooltiptextGrid::after {
    content: "";
    position: absolute;
    top: 12px;
    right: -12px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent #00a4bd;
  }
  
  .tooltip:hover .tooltiptextGrid {
    visibility: visible;
    opacity: 1;
  }

  .textShowFilterBetween {
    display: flex;
    justify-content: center;
    width: 50px;
  }

  .textShowFilter {
    display: flex;
    justify-content: center;
    width: 50px;
  }

  .containerActionTableUsers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .dropFilterUsers {
    z-index: 1;
    width: 250px;
    height: auto;
    position: absolute;
    top: -40px;
    right: 10%;
    background: var(--white);
    border: 1px solid #eff1f8;
    border-radius: 6px;
  }

  .massiveActionsUsers {
    display: flex;
    align-items: center;
    gap: 16px;
    position: absolute;
    right: 200px;
    top: -45px;
    text-align: center;
    width: 500px;
}

.massiveActionsUsers > .secondaryButton {
  /*width: 95px;*/
  height: 40px;
  border-radius: 6px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;    
  cursor: pointer;
  /* H6/H6 MEDIUM */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.massiveActionsUsers > .neutral {
  /* Third color */

  color: #748996;
  border: 1px solid #748996;
}

.massiveActionsUsers > .alert {
  /* Third color */

  /* BACKGROUND */
  color: #FAFAFB;
  background: #F06855;
}

.massiveActionsUsers > .okay {
  /* BACKGROUND */
  color: #FAFAFB;

  background: #07BFA7;
}

.actionFilter {
    display: flex;
    align-items: center;
    margin-top: 10px;
    text-align: center;
    justify-content: center;
}

.actionFilter > .secondaryButton {
    /*width: 95px;*/
    height: 40px;
    border-radius: 6px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;    
    cursor: pointer;
    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.actionFilter > .okay {
  /* BACKGROUND */
  color: #FAFAFB;

  background: #07BFA7;
}