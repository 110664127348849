.massiveActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;        
    height: 80px;
    gap: 16px; 
}

.containerButtonsFilter {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: -46px;
    text-align: center;
}

.massiveActions > .secondaryButton {
    /*width: 95px;*/
    height: 40px;
    border-radius: 6px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;    
    cursor: pointer;
    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.massiveActions > .neutral {
    /* Third color */

    color: #748996;
    border: 1px solid #748996;
}

.massiveActions > .alert {
    /* Third color */

    /* BACKGROUND */
    color: #FAFAFB;
    background: #F06855;
}

.massiveActions > .okay {
    /* BACKGROUND */
    color: #FAFAFB;

    background: #07BFA7;
}