@import url('https://fonts.googleapis.com/css2?family=Mulish');


.Title {
    position: absolute;
    /*min-width: 197px;*/
    height: 38px;
    left: 133px;
    top: 82px;

    display: flex;
    align-items: center;
}

.Title > .ico {
    position: absolute;
}

.Title H3 {

    margin-left: 30px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    color: var(--mainColor);
}

.pointerCursor {
  cursor: pointer;
}

.notification {    
    position: absolute;
    /*width: 536px;*/
    /*min-height: 722px;*/
    height: auto;
    left: 135px;
    top: 144px;
}

.notificationContent {
  position: relative;
    min-height: 722px;
    height: auto;

    padding-top: 16px;
    box-sizing: border-box;

    background: var(--white);

    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px 15px 15px 15px;
}

.previewNotify {
    position: absolute;
    width: 536px;
    height: 720px;
    left: 695px;
    top: 144px;
}

.previewContentNotify {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    padding-top: 50px;
    /*padding-bottom: 0px;*/

    /*padding-top: 50px;*/

    background: var(--white);

    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px;

    overflow: hidden;
}

/* ------------------------------------------------ */
 
/*

$background: var(--mainColor);
$tabs-base-color: grey;

* {
  margin: 0;
  padding: 0; 
  
}
body {
  padding: 20px;
  text-align: left;
  font-family: Lato;
  color: #fff; 
  background: $background;  
}
h1 {
  font-weight: normal;
  font-size: 40px;
  font-weight: normal;
  text-transform: uppercase;
  float: left;
  margin: 20px 0 100px 10px; 
  span { 
    font-size: 13px;
    display: block;
    padding-left: 4px;
  }
}
.tabs {
  width: 650px;  
  float: none;
  list-style: none;
  position: relative;
  margin: 80px 0 0 10px;
  text-align: left;
  li {
    float: left;
    display: block;
  }
  input[type="radio"] {
    position: absolute;
    top: 0;
    left: -9999px;
  }
  label {
    display: block;
    padding: 14px 21px;
    border-radius: 2px 2px 0 0;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    background: $tabs-base-color;
    cursor: pointer;
    position: relative;
    top: 4px; 
    
    &:hover {
      background: darken($tabs-base-color, 10);
    }
  }
  .tab-content{
    z-index: 2;
    display: none; 
    overflow: hidden;
    width: 100%;
    font-size: 17px;
    line-height: 25px;
    padding: 25px;  
    position: absolute;
    top: 53px;
    left: 0; 
    background: darken($tabs-base-color, 15);
  }
  //The Magic
  [id^="tab"]:checked + label { 
    top: 0;
    padding-top: 17px; 
    background: darken($tabs-base-color, 15); 
  }
  [id^="tab"]:checked ~ [id^="tab-content"] {
    display: block;
  }
}
p.link {
  clear: both;
  margin: 380px 0 0 15px;
  a {
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    margin: 0 5px;
    background-color: darken($tabs-base-color, 15);
    
    &:hover {
      background-color: darken($tabs-base-color, 20);
    }
  }
} 

*/