@import url("https://fonts.googleapis.com/css2?family=Mulish");

.Content {
  left: 135px;
}

.homeContent {
  position: absolute;

  top: 80px;
  left: 135px;
}

.homeHeadline {
  /*position: absolute;*/
  /*top: 80px;*/
  /*left: 133px;*/
}

.homeTitle {
  /*position: absolute;*/
  /*width: 250px;*/
  height: 40px;

  margin: 0px;
}

.homeTitle H1 {
  margin: 0px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  /*font-size: 2.027rem;*/
  font-size: 2.027vw;
  line-height: 2.566rem;

  color: var(--mainColor);
}

.homeSubTitle {
  /*position: absolute;*/
  /*width: 250px;*/
  height: 20px;
}

.homeSubTitle H3 {
  margin: 0px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  /*font-size: 1rem;*/
  font-size: 1vw;
  line-height: 1.266rem;

  color: var(--thirdColor);
}

.CardsContainer {
  min-width: 1096px;
  width: 85vw;

  margin-top: 16px;

  align-items: center;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  gap: 24px;
}

.cardCaption {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 2vw;
  margin-left: 2vw;
  margin-bottom: 0px;
}

.CardsContainer H2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  /*font-size: 18px;*/
  font-size: 1.2vw;
  /*line-height: 23px;*/
  line-height: 1.424vw;
  width: 100%;
  color: var(--mainColor);
}

.CardsContainer H6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  /*font-size: 14px;*/
  font-size: 0.889vw;
  /*line-height: 18px;*/
  line-height: 1.125vw;

  color: var(--thirdColor);
}

.CardsContainer > a {
  /*flex-grow: 1;*/
}

.icoCard {
  margin-left: 16%;
  margin-top: 16%;

  width: 3vw;
  height: 3vw;

  min-width: 36px;
  min-height: 36px;

  left: 16%;
  top: 16%;
}

.cardTitle {
  width: 80%;
  margin: 0px;
  /*margin-left: 20px;*/
}

.cardSubTitle {
  width: 80%;
  margin: 0px;
  /*margin-top: 20%;*/
  /*margin-left: 20px;*/
}

.homeCard {
  min-width: 192px;
  min-height: 192px;

  width: 15vw;
  height: 15vw;
  margin: 0.25vw 0.25vw;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;

  text-overflow: ellipsis;

  box-sizing: border-box;

  background: var(--white);

  box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
  border-radius: 15px;
}

.homeCard:hover {
  border: 1px solid var(--fourthColor);
}

.homeCardDisabled {
  min-width: 192px;
  min-height: 192px;

  width: 15vw;
  height: 15vw;
  margin: 0.25vw 0.25vw;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;

  text-overflow: ellipsis;

  box-sizing: border-box;

  cursor: not-allowed;

  filter: opacity(50%);
  -webkit-filter: opacity(50%);

  background: var(--white);
  border-radius: 15px;
}

.OtherSubTitle {
  /*position: absolute;*/
  /*width: 176px;*/
  height: 30px;
  /*left: 135px;
    top: 392px;   */
}

.OtherSubTitle H3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  /*font-size: 24px;*/
  /*font-size: 1.3rem;*/
  font-size: 1.7vw;
  line-height: 2.027rem;

  color: var(--mainColor);
}

.OtherCardContainer {
  /*position: absolute;*/

  margin-top: 16px;

  min-width: 1096px;
  min-height: 192px;

  width: 85vw;
  height: 15vw;

  /*left: 135px;
    top: 456px;*/

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  /*
  padding-top: 50%;
  padding-bottom: 50%;
  */
  box-sizing: border-box;

  background: var(--mainColor);
  mix-blend-mode: normal;
  border-radius: 15px;
}

.OtherCardContainer A {
  text-decoration: none;
  flex-grow: 3;
}

.OtherCardContainer H2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  /*font-size: 18px;
  line-height: 23px;*/

  font-size: 1.3vw;
  line-height: 1.424rem;

  display: block;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
}

.otherCard {
  cursor: pointer;
  color: var(--background);

  /*margin-top: 5vh;*/
}
.otherCard:hover {
  cursor: pointer;
  color: var(--thirdColor);
}

.otherCardDisabled {
  cursor: not-allowed;
  color: var(--thirdColor);
}

.otherIcoCard {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 3.8vw;
  height: 3.8vw;

  min-width: 49px;
  min-height: 49px;
}
 

.v-line {
  height: 30%;
  width: 0.01px;

  flex-grow: 0;
  margin: auto;

  /*color: var(--thirdColor);*/
  /*background-color: var(--thirdColor);*/

  border: 0.01px solid var(--thirdColor);
}

@media only screen and (max-width: 1366px) {
  .homeTitle H1 {
    font-size: 2.027rem;
  }
  .homeSubTitle H3 {
    font-size: 1rem;
  }
  .CardsContainer H2 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 23px;
  }
  .CardsContainer H6 {
    font-weight: 500;
    font-size: 0.889rem;
    line-height: 18px;
  }
  .OtherSubTitle H3 {
    font-size: 1.3rem;
  }
  .OtherCardContainer H2 {
    font-size: 1.125rem;
    line-height: 1.424rem;
  }
  .cardTitle {
    margin-left: 9px;
  }
  .cardSubTitle {
    margin-left: 9px;
  }
}
