.cleanColumns-title {

    margin-top: 16px;
    /* H3 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;

    text-align: center;

    /* MAIN COLOR */
    color: var(--mainColor);
}

.cleanColumns-caption {
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    
    /* identical to box height */
    text-align: center;
    
    /* MAIN COLOR */
    color: var(--mainColor);
}

svg.demoImg {
    display:block;
    margin:auto;
}

.activityPlaceHolder {    
    position: absolute;
    /*width: 1096px;*/
    width: 88%;
    
    min-height: 215px;
    height: auto;
    left: 135px;
    top: 250px;

    background: var(--white);

    box-sizing: border-box;    
    border: 1px solid #C4CACE;    
}