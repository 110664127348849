@import url("https://fonts.googleapis.com/css2?family=Mulish");

.manageContent {
position: absolute;
  left: 135px;
  top: 80px;
  left: 135px;
}

.manageHeadline {
  /*position: absolute;*/
  /*top: 80px;*/
  /*left: 133px;*/
}

.manageTitle {
  /*position: absolute;*/
  width: 302px;
  height: 40px;

  
  margin: 0px;
}

.manageTitle H1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 2.027rem;
  line-height: 2.566rem;
  color: var(--mainColor);
  margin: 0px;
}

.manageSubTitle {
  /*position: absolute;*/
  /*width: 577px;
    height: 20px;

    top: 40px;*/
    margin: 0px;
}

.manageSubTitle H3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--thirdColor);
  margin: 0px;
}

.CardsManageContainer {
  cursor: pointer;

  min-width: 1096px;
  width: 85vw;

  margin-top: 16px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  gap: 24px;
}

.CardsManageContainer H2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.424rem;

  color: var(--mainColor);
}

.CardsManageContainer H6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 0.889rem;
  line-height: 1.125rem;

  color: var(--thirdColor);
}

.CardsManageContainer > a {
}

.manageCardContent {
  /*background-color: red;*/

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 9px;
  margin-left: 25px;
}

.icoManageCard {
  /*position: absolute;*/
  /*left: 29px;    */
}

.manageCardTitle {
  /*position: absolute;
    left: 79px;*/
}

.manageCard {
  /*width: 200px;
    height: 80px;*/

  min-width: 192px;
  min-height: 80px;

  width: 15vw;
  height: 4vw;
  margin: 0.25vw 0.25vw;

  background: var(--white);

  box-sizing: border-box;

  box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
  border-radius: 15px;

  display: flex;
  align-items: center;
}

.manageCard:hover {
  border: 1px solid #00a4bd;
}

.manageCardDisabled {
  min-width: 192px;
  min-height: 80px;

  width: 15vw;
  height: 4vw;
  margin: 0.25vw 0.25vw;
  background: var(--white);

  border-radius: 15px;

  cursor: not-allowed;

  filter: opacity(50%);
  -webkit-filter: opacity(50%);

  display: flex;
  align-items: center;
}

.widgetsCard {
  /*position: absolute;
    left: 135px;
    right: 1031px;
    top: 168px;*/
}

.emailCard {
  /*position: absolute;
    left: 359px;
    top: 168px;*/
}

.newsletterCard {
  /*position: absolute;
    left: 583px;
    top: 168px;*/
}

.webCard {
  /*position: absolute;
    left: 807px;
    top: 168px;*/
}

.libraryCard {
  /*position: absolute;
    left: 1031px;
    top: 168px;*/
}

.manageOtherSubTitle {
  
}

.manageOtherSubTitle H3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 1.424rem;
  line-height: 1.802rem;

  color: var(--mainColor);

  
}

.activitySummary {

  min-width: 1096px;
  min-height: 192px;

  width: 85vw;
  height: 15vh;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

  gap: 50px;

  box-sizing: border-box;

  background: var(--mainColor);
  mix-blend-mode: normal;
  border-radius: 15px;
}

.activitySummary A {
  text-decoration: none;
}

.activitySummary H2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.424rem;

  display: block;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
}
