
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.contentMailPreview {
    background-image: url("../../../assets/images/MailPreview.png");
    background-color: var(--white);
    min-height: 720px; /* 720 */
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    border-radius: 15px;
    margin: auto;
    vertical-align: middle;
    
    clip-path: inset(0 0 50px 0);
    
}

.gmail {
    overflow: hidden;

    position: inherit;
    margin: auto;

    top: 70px;

    /*vertical-align: bottom;
    top: 82px;*/
    
    height: 420px;
    /*width: 270px;*/
    width: 43%;

 
}

.MailContent {

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    
    position:absolute;
    /*padding: 5px;*/
    vertical-align: middle;
    /*width: 260px;*/

    width: 100%;

    top: 0px;
    /*bottom:0px;*/

    
}

.Mail {
    
    border-radius: 5px;
    word-wrap: break-word;
    height:auto;
     
    margin: auto;
    width: 90%;
}

.titleMail {
    word-wrap: break-word;
    height:auto;

    padding: 5px 10px;

    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: black;
}

.textMail {

    word-wrap: break-word;
    height:auto;

    padding: 5px 10px;

    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: black;
}

.imageMail {
    width: 208px;
    border-radius: 6px;
}

.subject {
    border-radius: 5px;
    word-wrap: break-word;
    width: 100%;
    height: 30px;
    padding: 5px 10px;
    background-image: url("../../../assets/images/subject.png");
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
}

.subjectAura {
    border-radius: 5px;
    word-wrap: break-word;
    width: 100%;
    height: 30px;
    padding: 5px 10px;
    background-image: url("../../../assets/images/subjectAura.png");
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
}

