.modalConfigureUser {

  position: relative;
  width: 424px;
  height: 496px;

  display: flex;
  justify-content: center;
  align-content: center;

  background: var(--background);
  border: 1px solid var(--border2);

  /* DROP SHADOW */
  box-shadow: -8px 4px 45px var(--shadow);
  border-radius: 6px;
}

.contentModalConfigureUser {
  overflow-y: scroll;
  overflow-x: hidden;
  
  position: absolute;
  top: 56px;
  
  box-sizing: content-box;
  width: 415px; /*377*/
  height: 360px;   

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.modalConfigureUser > ::-webkit-scrollbar {
  width: 6px;    
}

.modalConfigureUser > ::-webkit-scrollbar-thumb {
  width: 6px;
  background: #D5D9DE;
  border-radius: 100px;    
}

.modalConfigureUser> ::-webkit-scrollbar-track {
  width: 6px;
  background: var(--white);
  border-radius: 100px;
}   

.lines {
  position: absolute;

  width: 100%;
  height: 1px;
  top: 46px;
  
  background-color: var(--border2);

  border: 1px solid var(--border2);
  box-sizing: border-box;
}

.textTitleModalConfigureUser {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 2.82%;
  bottom: 93.15%;

  /* H5/H5 MEDIUM */

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  /* MAIN COLOR */

  color: var(--mainColor);
}

.closeModalConfigureUser {
  position: absolute;
  width: 32px;
  height: 32px;
  left: 368px;
  top: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: rgba(196, 202, 206, 0.2);
  border-radius: 5px;
}

.labelFormText{   
  margin-bottom: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: var(--mainColor);
}

.half {
  width: 176px;    /* 176px; */ 
  height: 61px;
}

.twoHalf {
  width: 376px;    /* 376px; */
  height: 61px;
  margin-top: 16px;
}

.inputContentModalNormal {
  /* Rectangle 1 */
  box-sizing: border-box;

  width: auto;
  min-width: 176px;
  height: 40px;

  position: absolute;
  /*left: 5.66%;
  right: 5.66%;
  top: 45.16%;
  bottom: 46.77%;*/

  /* LINES BORDER CARD */
  border: 1px solid #C4CACE;
  border-radius: 6px;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  padding-left: 15px;

  /* MAIN COLOR */
  color: var(--mainColor);
}

.inputContentModalNormal:focus {
  outline: none;
}

.input_twoHalf {
  min-width: 376px;

}

.actionContentModalConfigureUser {   
  position: relative;
  bottom: 10px;
  margin: 5px;
}

.actionContentModalConfigureRole {   
  position: relative;
  bottom: 10px;
  margin: 5px;
  display: flex;
  align-items: flex-end;
}

.wrapper {
  width: 35px;
  position: relative;
}

.wrapper__select {
  width: 100%;
  opacity: 0;
}

.wrapper__flags {
  width: 100%;
  height: 100%;  
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.wrapper__flags__flag {
  height: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: none;
}
.wrapper__flags__flag.is-visible {
  display: block;
}

.text-step {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  color: #C4CACE;
}
.text-step-current {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  color: #FF8614;
}
.arrow-step {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  color: var(--mainColor);
  margin: 0px 12px;
}

.text-checkbox {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
  color: var(--mainColor);
}

.load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
  border-radius: 6px;
  height: 50px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--mainColor);
}

.load-more:hover {
  background-color: #e1e1e3;
}


.userSearch input {

  width: 321px;
  height: 48px;

  background: var(--back2);

  border: 1px solid #E1E1E1;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* Firefox 4+, Opera, Chrome */
/*
  background: url(../../../../assets/icons/navigation/search.svg) no-repeat scroll 7px 7px;
  background-size: contain;
*/
  box-sizing: border-box;
  border-radius: 6px;

  /* H6/H6 */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;  
  line-height: 18px;
  /* identical to box height */

  color: rgba(116, 137, 150, 0.7);
}

.userSearch {
  position:relative;
}

.icouserSearch {
  position:absolute;
  right: 17px;
top: 50%;
transform: translateY(-50%);
}