@import "../../assets/styles/index.scss";

.button {
  background-color: $Primary800 !important;
  width: 100%;
  border-radius: $BorderRadious8 !important;
  height: fit-content;
  min-height: 50px;
}

.viewMyWebSite {
  background-color: #e7f1fc !important;
  color: #3c91e6 !important;
  text-transform: capitalize !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight32 !important;
  letter-spacing: $LetterSpacing13 !important;
  font-size: $FontSize16 !important;
  border-radius: $BorderRadious8;
}

// .viewHelp {
//   background-color: #ffff !important;
//   color: $Secondary800 !important;
//   text-transform: capitalize !important;
//   font-family: $FontFamily3 !important;
//   line-height: $LineHeight32 !important;
//   letter-spacing: $LetterSpacing13 !important;
//   font-size: $FontSize16 !important;
//   border-radius: $BorderRadious8;
// }

.buttonToSave {
  background-color: $Primary800 !important;
  width: 100%;
  border-radius: $BorderRadious8 !important;
  height: 5.2vh;
  min-height: 35px;
  color: #ffff !important;
  cursor: auto;
  text-transform: capitalize !important;
  font-family: $FontFamily3 !important;
  font-size: $FontSize16 !important;
}

.buttonDisabled {
  background-color: $Gray5 !important;
  width: 100%;
  border-radius: $BorderRadious8 !important;
  height: 5.2vh;
  min-height: 35px;
  cursor: not-allowed;
  color: $BlackLow !important;
  text-transform: capitalize !important;
  font-family: $FontFamily3 !important;
  font-size: $FontSize16 !important;
}

.buttonLabel {
  text-transform: none !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight32 !important;
  letter-spacing: $LetterSpacing13;
  color: white !important;
  font-size: $FontSize16;
}

.buttonDiscard {
  text-transform: capitalize !important;
  font-family: $FontFamily2 !important;
  line-height: $LineHeight32 !important;
  letter-spacing: $LetterSpacing13;
  height: 5.2vh;
  background-color: white !important;
  font-size: $FontSize16;
  color: $Primary800 !important;
  width: 100%;
  border-radius: $BorderRadious8 !important;
}

.buttonToAdd {
  text-transform: capitalize !important;
  font-family: $FontFamily2 !important;
  line-height: $LineHeight32 !important;
  letter-spacing: $LetterSpacing13;
  height: 5.2vh;
  background-color: $Primary100 !important;
  font-size: $FontSize16;
  color: $Primary800 !important;
  width: 100%;
  border-radius: $BorderRadious8 !important;
}

//Button

.MuiButton-textSizeLarge {
  padding: 6px 15px !important;
}

.notAllowed {
  cursor: not-allowed;
}

.MuiButton-textSizeLarge {
  padding: 0px 14px !important;
}
