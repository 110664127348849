.popover{
    position: 'absolute';
    z-index: '2'; 
  }

.cover{
    position: 'fixed';
    top: '0px';
    right: '0px';
    bottom: '0px';
    left: '0px';
  }

  .LinkTreeUpladImage .ant-upload {
    width: inherit !important
 }