@import "../../../../assets/styles/index.scss";

.buttonsContainer {
  text-align: center;
  display: flex;
  align-content: center;
}

@media (max-width: 768px) {
  .buttonsContainer {
    display: block;
  }

}

.iconLink {
  margin-right: 5px;
  height: 18px;
}

.buttonCopyLink {
  text-transform: capitalize;
  line-height: 20px;
  color: white;
  background-color: $Primary700;
  width: auto;
  padding: 2px 5px;
  border-color: transparent;
  border-radius: 3px;
  margin-left: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-family: "DMSans-Regular";
  letter-spacing: 0.2px;
}

.buttonComponent {
  text-transform: capitalize !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight32 !important;
  letter-spacing: $LetterSpacing13 !important;
  color: white !important;
  background-color: $Primary700 !important;
  font-size: $FontSize16 !important;
  width: auto !important;
  padding: 2px 12px !important;
}

.bgAddButton {
  background-color: $Gray3;
  text-align: center;
  display: flex;
  align-content: center;
  height: 35px;
  border-radius: $BorderRadious4;
}

.addButton {
  text-align: center !important;
  display: flex !important;
  align-content: center !important;
  color: $BlackHalf !important;
  font-size: 20px !important;
}

.buttonPinnedButtons {
  color: $Primary700;
  width: 15px !important;
  margin-right: 5px;
  cursor: pointer;
}

.iconBottomButtom {
  color: $WhiteHalf;
  width: 15px !important;
  margin-right: 5px;
  cursor: pointer;
}
