@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.contentUhooBillboardPreview {
  background-image: url("../../../assets/images/BillboardPreview.png");
  background-color: var(--white);
  min-height: 720px;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  border-radius: 15px;
  margin: auto;
  vertical-align: middle;

  clip-path: inset(0 0 50px 0);
}

.billboardUhoo {
  overflow: hidden;

  position: inherit;
  margin: auto;

  top: 60px;

  height: 427px;
  width: 50%;
}

.billboardUhooContent {
  /*background-color: red;*/

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  position: absolute;
  vertical-align: middle;
  width: 100%;
  top: 45px;
}

.BillboardUhoo {
  background-color: var(--white);
  border-radius: 5px;

  word-wrap: break-word;
  height: auto;

  overflow: hidden;

  margin-left: 8px;
  width: 95%;

  box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
}

.titleBillboardUhoo {

  width: 85%;
  max-height: 65%;
  min-height: 15px;
  padding: 5%;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  overflow: hidden;

  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;

  text-align: center;

  color: white;

  z-index: 1;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  --shadowColor: black;
  /*-webkit-text-stroke: 0.01px black;*/
  text-shadow: 1px 0 var(--shadowColor), -1px 0 var(--shadowColor), 0 1px var(--shadowColor), 0 -1px var(--shadowColor),
               0.5px 0.5px var(--shadowColor), -0.5px -0.5px var(--shadowColor), 0.5px -0.5px var(--shadowColor), -0.5px 0.5px var(--shadowColor);
}

.textUhoo {
  word-wrap: break-word;
  height: auto;

  padding: 10px;

  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  color: black;
}

.imageBillboardUhoo {
  position: relative;
  display: inline-block;
  /*padding: 5%;*/
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;
  height: 120.594px;

  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.imageBillboardUhoo::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.billboardUhooMediaActions {
  word-wrap: break-word;

  width: 100%;
  height: 50px;

  border-radius: 4px;

  background-image: url("../../../assets/images/commentPost.png");
  background-color: var(--white);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
