@import "../../assets/styles/index.scss";

$margin7: 7%;
$margin10: 10%;
$margin12: 12%;

a {
  text-decoration: none;
  display: inline;
}

.titleStyleConfig {
  cursor: pointer;
  font-family: $FontFamily2;
  font-size: $FontSize20;
  line-height: 32px;
  letter-spacing: $LetterSpacing015;
  text-align: left;
  color: rgba(10, 17, 40, 0.87);
}

.conteinerTitleColumn {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 263px;
  margin-bottom: $margin7;
}
.conteinerTitleColumnAdd {
  display: flex;
  align-content: center;
  justify-content: space-between;
  min-width: 210px;
  margin-top: $margin12;
  margin-bottom: $margin7;
  border-bottom: 1px solid $Gray2;
  cursor: pointer;
}

.titleColumn {
  display: block;
  align-items: center;
  width: 100%;
}

.limitCharacters {
  text-overflow: Ellipsis; /* will make [...] at the end */
  width: 150px; /* change to your preferences */
  white-space: nowrap; /* paragraph to one line */
  overflow: hidden; /* older browsers */
}

.themeThumbnail img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  align-items: center;
}

.spaceLeft {
  margin-left: 2%;
}
@media (max-width: 1300px) {
  .spaceLeft {
    margin-left: 80px;
  }
}
@media (max-width: 1100px) {
  .spaceLeft {
    margin-left: 70px;
  }
}
@media (max-width: 900px) {
  .spaceLeft {
    margin-left: 50px;
  }
}
@media (max-width: 769px) {
  .spaceLeft {
    margin-left: 30px;
  }
}

.themeThumbnail img:hover {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  align-items: center;
  cursor: pointer;
}

.layoutThumbnail {
  object-fit: contain;
  align-items: center;
  position: relative;
  padding: 15px;
}

.layoutThumbnail img {
  object-fit: contain;
  align-items: center;
  position: relative;
  border: 2px solid #b3b3b3;
}

.cardList img {
  object-fit: contain;
  align-items: center;
  position: relative;
  border: 3px solid #f0f0f0;
}

.cardList img:hover {
  object-fit: cover;
  align-items: center;
  cursor: pointer;
}

.card {
  border-radius: $BorderRadious8 !important;
  box-shadow: 0 4px 8px 0 rgba(10, 17, 40, 0.1), 0 0 1px 0 rgba(10, 17, 40, 0.1) !important;
  background-color: #fff !important;
}

.icon {
  float: left;
  text-align: left;
}

.iconTitle {
  color: $WhiteHalf;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconTitleSection {
  color: $BlackHalf;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 15px;
}

.data {
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.item {
  background-color: white;
}

.data-mainpage {
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.2); */
  border-radius: 20px;
}

.containerCard-blockMove {
  border-radius: 8px;
  background-color: $Gray5;
  border: 1px solid $Primary50;
}

.containerCard {
  border: 1px solid $Primary50;
  border-radius: 8px;
  background-color: $Primary0;
  width: 263.5px;
  margin-right: 50px;
  padding: 5% 0%;
  height: 100%;
}

.containerCardVertical {
  border: 1px solid $Primary50;
  border-radius: 8px;
  background-color: $Primary0;
  width: 263.5px;
  margin-right: 50px;
  padding: 2% 2%;
  height: 100%;
}

.addNew-Plus {
  margin: 8px;
  border: 1px solid #666666;
  background-color: #f5f5f5;
  width: min-content;
  border-radius: 25px;
  cursor: not-allowed !important;
}

.addNewSection-Plus {
  border: 1px solid #666666;
  background-color: #ffffff;
  cursor: not-allowed !important;
  /* background-color: white; */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px;
  margin: 7px;
  align-items: center;
  height: 100px;
  display: inline-block;
  width: 300px;
}

.containerTask {
  padding: 6px;
  margin: 8px 1px;
  border-radius: $BorderRadious8;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing02;
  text-align: center;
  background-color: $Primary100;
  font-family: $FontFamily3;
  //height: 20px;
  display: flow-root;
  justify-content: center;
  align-content: center;
  a {
    color: $Primary800;
  }
}

.containerTaskIntoGroupSubpage {
  padding: 6px;
  margin: 8px 1px;
  border-radius: $BorderRadious8;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing02;
  text-align: center;
  background-color: $Success100;
  font-family: $FontFamily3;
  // height: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  a {
    color: $Success800;
  }
}

.containerTaskIntoSubpage {
  padding: 6px;
  margin: 8px 1px;
  border-radius: $BorderRadious8;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing02;
  text-align: center;
  background-color: $Error100;
  font-family: $FontFamily3;
  //height: 20px;
  display: flow-root;
  justify-content: center;
  align-content: center;
  a {
    color: $Error800;
  }
}

.containerTask-Disabled {
  padding: 6px;
  margin: 8px 1px;
  border-radius: $BorderRadious8;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing02;
  text-align: center;
  background-color: $Gray3;
  cursor: not-allowed !important;
  font-family: $FontFamily3;
  height: 20px;
  display: flex;
  justify-content: center;
  align-content: center;

  a {
    color: $BlackHalf;
  }
}

.containerIndex {
  display: flex;
  width: 200px;
  /* height: min-content; */
  /* white-space: nowrap; */
}

.TaskList {
  padding: 0px 30px;
  flex-grow: 1;
  min-height: 100px;
}

.icon {
  float: left;
  text-align: left;
}

.data {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.textP {
  color: black;
  margin-top: 20%;
  cursor: pointer;
  text-align: center;
}

.textP2 {
  color: black;
  /* margin-top: 10%; */
  cursor: pointer;
  text-align: center;
}

.tagMainpage {
  color: $Primary600 !important;
  text-align: left;
  text-transform: capitalize !important;
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing019;
  width: 100% !important;
  align-items: center;
  display: flex;
  padding: 2.5%;
}

.backgroundTagMainpage {
  background-color: $Primary100;
  width: 35.1%;
  padding: 0% 4%;
  border-radius: $BorderRadious4;
  display: flex;
  align-content: center;
  margin-bottom: $margin7;
}

.tagSubpage {
  color: $Success800 !important;
  text-align: left;
  text-transform: capitalize !important;
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing019;
  width: 100% !important;
  align-items: center;
  display: flex;
  padding: 2.5%;
}

.backgroundTagSubpage {
  background-color: $Success100;
  width: max-content;
  padding: 0% 2%;
  border-radius: $BorderRadious4;
  display: inherit;
}

.tagGroupsubpage {
  color: $Warning800 !important;
  text-align: left;
  text-transform: capitalize !important;
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing019;
  width: 100% !important;
  align-items: center;
  display: flex;
  padding: 2.5%;
}

.backgroundTagGroupsubpage {
  background-color: $Warning100;
  width: max-content;
  padding: 0% 2%;
  border-radius: $BorderRadious4;
  display: inherit;
}

.tagSection {
  color: $Error800 !important;
  align-self: center;
  text-transform: capitalize !important;
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: $LineHeight22;
  letter-spacing: $LetterSpacing019;
  width: 100% !important;
}

/* FORMS CSS  */

.layoutSelector {
  // margin-left: 70px;
  position: relative;
}

$primary: #3c1361;
$secondary: #663a82;
$white: black;
$gray: #9b9b9b;
.form__group {
  position: relative;
  padding: 30px;
  // margin-top: 10px;
  width: 100%;
}

.form__field {
  font-size: 30px;
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight: 700;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.containerHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  padding-right: 2%;
}

.containerHeaderButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 3%;
    padding-right: 2%;
}

.breadcrumb {
  display: flex;
  text-align: left;
}

.spaceBreadcrumb {
  padding-top: 2%;
  padding-bottom: 0%;
}

.spaceFromBreadcrumb {
  padding-top: 2%;
}

.buttonsHeader {
  display: flex;
  justify-content: space-around;
}

.addGroupSubpageOrSubpage {
  padding: 0px;
  margin: 0px;
  margin-bottom: 6%;
  margin-left: 5%;
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: 21.2px;
  letter-spacing: 0.44px;
  color: $WhiteHalf;
}

.containerThemes {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.cardSelectTheme {
  width: 175px;
  // display: flex;
  // flex-direction: column;
}

.cardSelectTheme .tooltipDescription {
  display: none;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 20px;
  background-color: $Primary500;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.125rem;
  color: var(--white);
  text-align: center;
  justify-content: center;
  // position: absolute;
  width: 100px;
  transition: 1 ease-out;
  margin: 12px 15px;
}

.cardSelectTheme:hover .tooltipDescription {
  transition: 1 ease-out;
  display: flex;
  visibility: visible;
  opacity: 1;

}

.tooltipTheme .tooltipThemeDescription {
  visibility: hidden; /*hidden;*/
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 16px;
  background-color: $Primary500;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 0.889vw;
  line-height: 1.125rem;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  position: sticky;
  z-index: 100;
  top: 0;
  left: 33px;
  opacity: 0;
  transition: opacity 0.3s;
  width: auto;
}

.tooltipTheme:hover .tooltipThemeDescription {
  visibility: visible;
  opacity: 1;
}

.imageThemeModal {
  max-width: 70vw;
  max-height: 70vh;
}

//Table

.MuiTableCell-head {
  color: $Primary700 !important;
  font-size: $FontSize16 !important;
  font-family: $FontFamily2 !important;
  line-height: $LineHeight22 !important;
  letter-spacing: $LetterSpacing02 !important;
  padding: 10px !important;
}

.MuiTableCell-body {
  color: $BlackHalf !important;
  font-size: $FontSize14 !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight22 !important;
  letter-spacing: $LetterSpacing02 !important;
  padding: 10px !important;
}

.containerIconsTable {
  display: flex;
  // justify-content: space-between;
}

.bgIconEdit {
  background-color: $Success100;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  margin-right: 15px;
}

.bgIconEditStreaming {
  background-color: $Success100;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  margin-right: 5px;
}

.iconEdit {
  color: $Success800;
  width: $FontSize16;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgIconEye {
  background-color: $Primary100;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  // margin-right: 15px;
}

.bgIconEyeStreaming {
  background-color: $Primary100;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  margin-right: 5px;
}

.iconEye {
  color: $Primary800;
  width: $FontSize16;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgIconTrash {
  background-color: $Error100;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
}

.iconTrash {
  cursor: pointer;
  color: $Error800;
  width: $FontSize16;
  display: flex;
  align-items: center;
  justify-content: center;
}

//Radio button
.MuiRadio-colorSecondary.Mui-checked {
  color: $Primary600 !important;
}

//Card
.MuiCard-root {
  overflow-x: auto !important;
  overflow-y: auto !important;
  overflow: auto !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
  // overflow: auto !important;
}

.buttonAtTheBottom {
  display: flex;
  // padding-right: 5%;
  justify-content: flex-end;
  margin-bottom: 3%;
}

// Divider

.MuiDivider-root {
  margin-right: 20px !important;
}

//Dialog

.MuiDialog-paperWidthSm {
  min-width: 500px !important;
}

// Text radiobutton

// .MuiTypography-body1 {
//   color: $BlackHalf !important;
//   font-size: $FontSize14 !important;
//   font-family: $FontFamily3 !important;
//   font-weight: 400 !important;
//   line-height: $LetterSpacing015 !important;
//   letter-spacing: $LetterSpacing02 !important;
// }

.iconDeleteSectionSubpage {
  color: $Error300;
  display: flex;
  justify-content: flex-end;
  transform: translateY(-10px);
}

.iconDeleteSectionMainpage {
  color: $Primary300;
  display: flex;
  justify-content: flex-end;
  transform: translateY(-10px);
}

.containerIconDeleteSection {
  position: absolute;
  height: 100%;
  top: 8px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
