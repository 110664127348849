.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-1v4ccyo {
    width: 100%;
    min-width: 150px;
    max-width: 200px;
}

.modalConfigureCommunity { 
    position: relative;
    width: 20%;
    min-width: 350px;
    max-width: 700px;
    height: auto;

    background: var(--background);
    border: 1px solid var(--border2);

    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
    align-items: flex-end;
}

.modalEditWigdet {
    position: relative;
    width: auto;
    min-height: 496px;
    height: auto;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: var(--background);
    border: 1px solid var(--border2);
    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
    /* height: 90vh; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.modalDeleteWigdet {
    position: relative;
    width: 424px;
    min-height: 496px;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: var(--background);
    border: 1px solid var(--border2);
    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.modalConfigureAudience {

    position: relative;
    width: 536px;
    min-height: 200px;
    height: auto;

    display: flex;
    justify-content: center;
    align-content: center;

    background: var(--background);
    border: 1px solid var(--border2);

    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.contentInfoModalConfigureAudience {
    display: flex;
}

.contentModalConfigureUser {
    overflow-y: scroll;
    overflow-x: hidden;
    
    position: absolute;
    top: 56px;
    
    box-sizing: content-box;
    width: 415px; /*377*/
    height: 360px;   

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.modalConfigureUser > ::-webkit-scrollbar {
    width: 6px;    
}

.modalConfigureUser > ::-webkit-scrollbar-thumb {
    width: 6px;
    background: #D5D9DE;
    border-radius: 100px;    
}

.modalConfigureUser> ::-webkit-scrollbar-track {
    width: 6px;
    background: var(--white);
    border-radius: 100px;
}   

.linesCommunity {
    position: inherit;

    width: 100%;
    height: 1px;
    
    background-color: var(--border2);

    border: 1px solid var(--border2);
    box-sizing: border-box;
}

.textTitleModalConfigureCommunity {
    position: relative;
    
    width: 100%;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* H5/H5 MEDIUM */

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    /* MAIN COLOR */

    color: var(--mainColor);
}

.closeModalConfigureCommunity {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background: rgba(196, 202, 206, 0.2);
    border-radius: 5px;
}

.inputContentModalConfigureCommunity {
    /* Rectangle 1 */
    box-sizing: border-box;

    width: inherit;
    min-width: 176px;
    height: 40px;

    position: absolute;
    /*left: 5.66%;
    right: 5.66%;
    top: 45.16%;
    bottom: 46.77%;*/

    /* LINES BORDER CARD */
    border: 1px solid #C4CACE;
    border-radius: 6px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    padding-left: 15px;

    /* MAIN COLOR */
    color: var(--mainColor);
}

.inputContentModalConfigureCommunity:focus {
    outline: none;
}



.buttonActions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}