@import url('https://fonts.googleapis.com/css2?family=Mulish');

/* -------------------- create post from --------------- */
.contentsPostform {
    
    width: 472px;
    /*min-height: 622px;*/

    height: auto;

    margin: 0px 32px 0px 32px;
 
    justify-content: flex-start;
    flex-wrap: nowrap;

    display: flex;
    flex-direction: column;

    /*background-color: aquamarine;*/
}

.date{
    margin-top: 16px;
    margin-bottom: 72px;
}

.PostformTitles {

    margin-top: 11px;
    margin-bottom: 11px;

    /* H4/H4 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    /* MAIN COLOR */
    color: var(--mainColor);
}

.fontStyleIconUrl {
    width: 32px;
    height: 32px;
    position: relative;
    top: -141px;
    left: 179px;
    cursor: pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    background: #F5F5FD;
    border-radius: 5.25926px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    /* Third color */
    color: #748996;
}

/* -------------------- date --------------- */

.date {
    /*position: absolute;        
    top: 74px;*/

    left: 31px;
    width: 472px;
    height: 30px;

    display: grid;
    grid-template-columns: 1fr, 1fr;
    grid-column-gap: 24px;

    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    /* Third color */
    color: #748996;
}

.startDate {
    grid-column: 1;

}

.finalDate {
    grid-column: 2;

}

/* -------------------- message --------------- */

.message {    
    display:flex;
    flex-direction: column;
}



/*
.fontStyle {
    width: 32px;
    height: 32px;

    cursor: pointer; 

    display:flex;
    justify-content: center;
    align-items: center;

    background: #F5F5FD;
    border-radius: 5.25926px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;

    color: #748996;
    
}
*/
.listAttachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 32px;

    gap: 18px 15.5px; 

    align-items: center;
    align-content: center;
}

.attachments {
    display:flex;
    justify-content: center;
    align-items: center
}

.attachmentsBlocked {
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
}

.contentAttachments {
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    width: 228.12px;
    height: 104px;
    margin: 15.88px;
    border: 1px dashed #748996;
    box-sizing: border-box;
    border-radius: 5px;
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* Third color */
    color: #748996;
    display:flex;
    justify-content: center;
    align-items: center;
}

.contentAttachmentsBlocked {
    display:flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    flex-direction: column;
    width: 228.12px;
    height: 104px;
    margin: 15.88px;
    border: 1px dashed #748996;
    box-sizing: border-box;
    border-radius: 5px;
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* Third color */
    color: #748996;
    display:flex;
    justify-content: center;
    align-items: center;
}

.createPostActionsEmail {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
}

.createPostActions {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
}

.createPostActions .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

.createPostActions:hover .tooltiptext{
    visibility: visible;
}

.createPostActionsNotAllowed {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
    cursor: not-allowed;
}

.attach {
    background: #F5F5FD;
    border-radius: 6px;
    
    cursor: pointer;

    margin: 8px;

    width: 48px;
    height: 48px;
    
    display:flex;
    justify-content: center;
    align-items: center;
}
.notify {
    background: #F5F5FD;
    color: #748996;
    border-radius: 6px;

    cursor: pointer;

    margin: 8px;

    width: 48px;
    height: 48px;

    display:flex;
    justify-content: center;
    align-items: center;
}

.notifyActive{
    border: 1px solid #00A4BD;
    box-sizing: border-box;
    color: #00A4BD;
}

.schedule {
    width: 127px;
    height: 48px;
    /* Auto layout 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /*padding: 14px 20px 5px;*/

    cursor: pointer;
    margin: 8px;

    display:flex;
    justify-content: center;
    align-items: center;

    /* SECOND COLOR */
    border: 1px solid #FF8614;
    box-sizing: border-box;
    border-radius: 6px;

    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FF8614;
}
.toPost {
    width: 127px;
    height: 48px;
    cursor: pointer;
    margin: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    /* Second color */
    background: #FF8614;
    border-radius: 6px;
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--white);
}

.toPostDisabled {
    width: 127px;
    height: 48px;
    cursor: not-allowed;
    margin: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    /* Second color */
    background: gray;
    border-radius: 6px;
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--white);
}





/* tooltip */

.postTooltipBlocked {
    visibility: hidden; /*hidden; ---*/

    display:flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 16px;

    background-color: #00A4BD; 
    border-radius: 4px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */


    color: var(--white);
    text-align: center;
    white-space: nowrap;
    
    position: absolute;
    z-index: 1;
    top: 101%;
    

    /*left: 33px;*/
    
    opacity: 0; /*0; --- */
    transition: opacity 0.3s; /*0.3*/
}

.postTooltip .tooltiptext {

    visibility: hidden; /*hidden; ---*/

    display:flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 16px;

    background-color: #00A4BD; 
    border-radius: 4px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */


    color: var(--white);
    text-align: center;
    white-space: nowrap;
    
    position: absolute;
    z-index: 1;
    top: 101%;
    

    /*left: 33px;*/
    
    opacity: 0; /*0; --- */
    transition: opacity 0.3s; /*0.3*/
}
  
.postTooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #00A4BD transparent;
}
  
.postTooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.postTooltip .tooltiptextImage {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 16px;
    background-color: #00A4BD;
    border-radius: 4px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--white);
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 73%;
    opacity: 0;
    transition: opacity 0.3s;
    width: 300px;
}
  
.postTooltip .tooltiptextImage::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #00A4BD transparent;
}
  
.postTooltip:hover .tooltiptextImage {
    visibility: visible;
    opacity: 1;
}

half

.inputFormsHalf {

    /*position: absolute;
    left: 5.97%;
    right: 5.97%;*/

    padding-left: 23.31px;    

    width: 222px;
    height: 56px;

    border: 0;
    
    background: #F5F5FD;
    border-radius: 6px;

    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */


    /* Third color */

    color: #748996;


}

.inputFormsDate {
    padding-left: 23.31px;    
    padding-right: 23.31px;    
    box-sizing: border-box;
    width: 100%;
    max-width: 222px;
    height: 56px;
    border: 0;
    background: #F5F5FD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* Third color */
    color: #748996;
}

.inputFormsDateBlocked {
    padding-left: 23.31px;    
    padding-right: 23.31px;    
    box-sizing: border-box;
    width: 100%;
    max-width: 222px;
    height: 56px;
    border: 0;
    background: #F5F5FD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    pointer-events: none;
    color: #748996;
}

.inputForms {
    padding-left: 23.31px;    
    padding-right: 23.31px;    
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border: 0;
    background: #F5F5FD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* Third color */
    color: #748996;
}

.inputFormsBlocked {
    padding-left: 23.31px;    
    padding-right: 23.31px;    
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    border: 0;
    background: #F5F5FD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* Third color */
    color: #748996;
    cursor: not-allowed;
    pointer-events: none;
}

.notClick {
    pointer-events: none;
}

.css-18lrjg1-MuiCircularProgress-root {
    color: #FF8614 !important;
}

.circularProgressCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 736px;
    background-color: white;
    position: absolute;
    width: 538px;
    left: 0px;
    top: 0px;
    border-radius: 16px;
    z-index: 99;
}

