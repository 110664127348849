@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.contentUhooWidgetPreview {
  background-image: url("../../../assets/images/WhidgetPreview.png");
  background-color: var(--white);
  min-height: 720px; 
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 15px;
  margin: auto;
  vertical-align: middle;
  clip-path: inset(0 0 50px 0);
}

.contentAuraWidgetPreview {
  background-image: url("../../../assets/images/WhidgetPreviewAura.png");
  background-color: var(--white);
  min-height: 720px; 
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 15px;
  margin: auto;
  vertical-align: middle;
  clip-path: inset(0 0 50px 0);
}

.widgetUhoo {
  overflow: hidden;

  position: inherit;
  margin: auto;

  top: 60px;

  height: 427px;
  width: 50%;
}

.widgetUhooContent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  position: absolute;
  vertical-align: middle;
  width: 100%;
  top: 45px;
}

.WidgetUhoo {
  background-color: var(--white);
  border-radius: 5px;

  word-wrap: break-word;
  height: auto;

  overflow: hidden;

  margin-left: 8px;
  width: 45%;

  box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
}


.titleWidgetUhoo {
  width: 90%;

  padding: 5%;
  text-overflow: ellipsis;
  white-space: pre;

  overflow: hidden;

  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.textUhoo {
  
  word-wrap: break-word;
  height: auto;

  padding: 10px;

  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  color: black;
}

.imageWidgetUhoo {
  position: relative;
  display: inline-block;
  padding: 5%;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;
  height: 120.594px;
}

.imageWidgetUhoo::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.widgetUhooMediaActions {
  word-wrap: break-word;

  width: 100%;
  height: 50px;

  border-radius: 4px;

  background-image: url("../../../assets/images/commentPost.png");
  background-color: var(--white);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
