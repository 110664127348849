.cardUser {
  position: relative;
  width: calc(25% - 32px);
  height: calc(50% - 20px);
  min-width: 240px;
  min-height: 144px;
  background: var(--back2);
  mix-blend-mode: normal;
  border-radius: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.cardUserDeleted {
  position: relative;
  width: calc(25% - 32px);
  height: calc(50% - 20px);
  min-width: 240px;
  min-height: 144px;
  background: #fff2f1;
  mix-blend-mode: normal;
  border-radius: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.cardUserSelect {
  /*background-color: var(--linesBorderCard);
    border: 1px solid var(--linesBorderCard);
    border-radius: 2px;*/

  position: absolute;
  left: 6.67%;
  right: 87.92%;
  /*top: 11.11%;*/
  top: 1vh;
  bottom: 79.86%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cardUserOption {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 88.42%;
  right: 4.17%;
  /*top: 11.11%;*/
  top: 1vh;
  bottom: 79.86%;

  display: flex;
  justify-content: center;
  align-items: center;
}

#OptionCard {
  width: 20px;
  height: 20px;
}

.cardUserContent {
  position: relative;

  /*width: 78px;
  height: 108px;*/
  display: flex;
  justify-content: center;
}

.cardUserImage {
  background: #ffc3ba;

  border-radius: 50%;

  --imageRadius: 9.5vh;

  width: var(--imageRadius);
  height: var(--imageRadius);

  min-width: 50px;
  min-height: 50px;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 50%;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height */

  color: #b36256;
}

.cardUserImage > img {
  width: 100%;
  height: 100%;

  object-fit: cover; /*contain*/

  border-radius: 50%;
}

.cardUserInfo {
  position: absolute;
  left: 0%;
  right: 0%;
  /*top: 55.56%;
  bottom: 13.89%;*/

  top: 2vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.9vh;
}

.cardUserName {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;

  font-size: 1vw;
  line-height: 1.226vw;

  text-align: center;

  width: 300px;

  /* MAIN COLOR */
  color: var(--mainColor);
}

.cardUserRole {
  width: 300px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;

  font-size: 0.889vw;
  line-height: 1.125vw;

  text-align: center;

  color: var(--thirdColor);
}

.actionViewTrue {
  height: 128px;
}

.actionViewFalse {
  height: 144px;
}

.actionViewShow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;

  gap: 15px;
}

/* --- --- --- */

.dropCommunity-content {
  display: none;
  z-index: 1;
  width: 80%;
  height: 50%;
  max-width: 240px;
  max-height: 120px;
  min-height: 120px;
  position: absolute;
  top: calc(15% + 1vh);
  right: 7%;
  /*left: -6.67%;
  right: 6.67%;
  top: 22.22%;
  bottom: -5.56%;*/
  /* WHITE */
  background: var(--white);
  /* BORDER 2 */
  border: 1px solid #eff1f8;
  border-radius: 6px;
}

.dropCommunity {
  position: absolute;
  width: 240px;
  height: 120px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-evenly;
}

.show {
  display: block;
}

.dropOptionCommunity {
  cursor: pointer;
  width: 100%;
  margin-left: 24px;

  /* H6/H6 */
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  /*font-size: 0.889rem;
  line-height: 1.125rem;*/

  font-size: 0.889vw;
  line-height: 1.125vw;

  /* MAIN COLOR */
  color: var(--mainColor);
}

@media only screen and (min-height: 750px) {
  .cardUserImage {
    top: -9vh;
  }
  .cardUserInfo {
    top: calc(3vh);
  }
  .cardUserImage {
    top: -10vh;
  }
  .cardUserContent{
    top: 2vh;
  }
}

@media only screen and (min-width: 2377px) {
  .cardUser {
    min-height: 244px;
  }
  .cardUserImage {
    top: -12vh;
  }
  .cardUserInfo {
    top: 1vh;
  }
}

@media only screen and (max-width: 1366px) {
  .cardUserName {
    font-size: 16px;
    line-height: 20px;
  }

  .cardUserRole {
    font-size: 14px;
    line-height: 18px;
  }

  .dropOptionCommunity {
    font-size: 14px;
    line-height: 18px;
  }
  .cardUserSelect {
    top: 11.11%;
  }
  .cardUserOption {
    top: 11.11%;
  }
}