@import url('https://fonts.googleapis.com/css2?family=Mulish');

.TitleWigdet {
  position: absolute;
  height: 38px;
  left: 143px;
  top: 82px;
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-width: 730px;
  width: 80%;
}

.TitleWigdet > .ico {
  position: absolute;
}

.TitleWigdet H3 {
  margin-left: 30px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--mainColor);
}

.Title {
    height: 38px;
    left: 133px;
    top: 82px;
    display: flex;
    align-items: center;
}

.Title > .ico {
    position: absolute;
}

.Title H3 {

    margin-left: 30px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--mainColor);
}

.pointerCursor {
  cursor: pointer;
}

.widget {    
    position: absolute;
    width: 536px;
    height: auto;
    left: 135px;
    top: 180px;
}

.widgetContent {
  position: relative;
    min-height: 722px;
    height: 100vh;
    background: var(--white);
    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px 15px 15px 15px;
    min-width: 730px;
  }

.widgetList {
    width: calc(90% - 150px);
    height: auto;
    position: absolute;
    left: 135px;
    top: 180px;
  }

.previewWidget {
    position: absolute;
    width: 536px;
    height: 720px;
    left: 695px;
    top: calc(180px); /* para alinear con tabs + 48px*/
}

.previewWidgetTabs {
    position: absolute;
    width: 536px;
    height: 720px;
    left: 695px;
    top: calc(144px + 48px); /* para alinear con tabs + 48px*/
}

.previewContentWidget {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    padding-top: 50px;

    overflow: hidden;

    background: var(--white);

    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px;
}

/* ------------------------------------------------ */
 
.widgetListContent {
  
  position: absolute;

  left: 135px;
  top: 160px;

  width: 1098px;
  height: 408px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 24px 24px; 

  align-items: center;
  align-content: center;
  justify-content: center;
}

.containerTitlePage {
  height: 38px;
  left: 133px;
  top: 82px;
  display: flex;
  align-items: center;
}

.menssageAlerLengthItem {
  height: 38px;
  left: 150px;
  top: 127px;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1.125rem;
  line-height: 23px;
  font-family: "Mulish";
  width: 100%;
  color: orange;
  border: 1px solid;
  width: auto;
  padding: 1px 15px;
  border-radius: 15px;
}