.containerCommunityActions {

    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-wrap: nowrap;
    flex-direction: row;

    gap: 16px;
    width: 86%;
    min-width: 1096px;
}

@media (min-width: 1360px) {
    .containerCommunityActions {
        width: 82%;
    }
}

@media (min-width: 1920px) {
    .containerCommunityActions {
        width: calc(100vw * 0.905);
    }
}


.communitySearch input {

    width: 321px;
    height: 48px;

    background: var(--back2);

    border: 1px solid #E1E1E1;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* Firefox 4+, Opera, Chrome */
/*
    background: url(../../../../assets/icons/navigation/search.svg) no-repeat scroll 7px 7px;
    background-size: contain;
*/
    box-sizing: border-box;
    border-radius: 6px;

    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;  
    line-height: 18px;
    /* identical to box height */

    color: rgba(116, 137, 150, 0.7);
}

.communitySearch {
    position:relative;
}

.icoCommunitySearch {
    position:absolute;
    right: 17px;
	top: 50%;
	transform: translateY(-50%);
}

.displayNone {
    display: none;
}

.inputSearch:focus {
    outline: none;
    background: var(--back2);    
}

.inputSearch::-webkit-search-cancel-button {
    position:relative;
    right:14px;  

    cursor: pointer;
  
    -webkit-appearance: none;
    height: 10px;
    width: 10px;

    background: url(../../../../assets/icons/navigation/closeSearch.svg) no-repeat 50% 50%;
    background-size: contain;
}

.importButton {
    width: 48px;
    height: 48px;

    cursor: pointer;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;    

    /* SECOND COLOR */
    border: 1px solid #FF8614;
    border-radius: 6px;
}

.importButton:hover {
    filter: opacity(50%);
}

.disableButton {
    color: gray;
}