.pictureItem {
    
    position: relative;
    background: var(--white);
    box-shadow: -8px 4px 30px rgba(112, 144, 176, 0.1);
    border-radius: 15px;
    width: calc(25% - 30px);
    height: calc(25vh - 20px);
    min-width: 245px;
    min-height: 225px;
    mix-blend-mode: normal;
    border-radius: 15px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.fileItem {
    position: absolute;
    display: flex;
    justify-content: center; 
    width: 184px;
    height: 113px;  

    margin-top: 7px;
    margin-left: 8px;
    margin-right: 8px;    

    border-radius: 8px;
}

.fileItem > img {
    width: 184px;
    height: 113px;
    object-fit: cover; /*contain*/
    border-radius: 8px;
}

.fileItemDoc {
    position: absolute;
    width: 100px;
    height: 120px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 8px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
}

.selectItem  {
    position: absolute;
    left: 8%;
    right: 86%;
    top: 10.94%;
    bottom: 82.81%;  
}

.selectItem input  {
    position: absolute;
    /*opacity: 0;*/
    cursor: pointer;
}

.selectItemABC {
    position: absolute;
    left: 8%;
    right: 86%;
    top: 10.94%;
    bottom: 82.81%;

    width: 12px;
    height: 12px;

    cursor: pointer;

    /* BACKGROUND */
    background: #FAFAFB;
    
    /* SECOND COLOR */
    border: 1px solid #FF8614;
    box-sizing: border-box;
    border-radius: 2px;

    display: flex;
    justify-content: center;
    align-items: center;     
}

.selectChoice {
    position: absolute;
    left: 8%;
    right: 86%;
    top: 10.94%;
    bottom: 82.81%;

    width: 12px;
    height: 12px;

    background-color: #FAFAFB;
    border: 1px solid #FF8614;
    /*box-sizing: border-box;*/
    border-radius: 2px;
}

.downloadItem {

    position: absolute;

    cursor: pointer;
    
    border-radius: 50px;
    /*z-index: 1;*/

    width: 24px;
    height: 24px;

    left: 79.5%;
    right: 8.5%;
    top: 8.33%;
    bottom: 79.17%;


    /* BACKGROUND */
    background: #FAFAFB;

    display: flex;
    justify-content: center;
    align-items: center;
}

.nameItem {
    position: absolute;
    left: 8%;
    top: 77.75%;
    max-width: 129.36px;
    overflow-x: hidden;
    overflow-y: hidden;
    /* H5/H5 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* MAIN COLOR */
    color: var(--mainColor);
}

.dateItem {
    position: absolute;
    left: 8%;
    bottom: 7.99%;
    /*margin-left: 11.76px;*/
    max-width: 129.36px;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #748996;
}