/* Social Network */

@import "../../../../assets/styles/index.scss";

.socialNetworkIcons {
  margin-top: 2%;
  margin-left: 3%;
  margin-right: 2%;
  width: 4%;
}

.aurappLogo {
  width: 35px;
  height: 35px;
}

.socialNetworkDrag {
  margin: 2%;
  width: 2%;
}

.socialNetworkInput {
  width: 90%;
  margin-top: 1%;
}

.socialNetworkSubtitle {
  font-size: $FontSize16 !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight24 !important;
  letter-spacing: $LetterSpacing02 !important;
  color: $BlackHigh !important;
  margin: 1% 0% 1% 2%;
}

.socialNetworkCheckbox {
  display: flex;
  justify-content: flex-start;
  cursor: not-allowed;
  
}