.viewPostComments {
    
    width: 310px;    
    height: auto;

    margin-left: auto;
    margin-right: auto;
    
    margin-top: 8px;
    box-sizing: border-box;    
}

.headViewPostComments {

    margin-top: 8px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    gap: 8px;
}

.actionViewPostComments {
    /*background-color: red;*/
    width: 13px;
    height: 13px;

    justify-content: center;
    align-items: center;

    display: flex;

    margin-left: auto;
    margin-right: 8px;
}

.imgUserViewPostComments{
    
    background-color: var(--auxiliarColor2);

    width: 24px;
    height: 24px;

    border-radius: 50%;
}

.imgUserViewPostComments > img {

    width: 24px;
    height: 24px;

    object-fit: cover; /*contain*/
    
    border-radius: 50%;
}

.userViewPostComments{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;

    color: var(--mainColor);
}

.dateViewPostComments {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;

    color: #748996;
}

.txtContentViewPostComments {
    margin-left: auto;
    margin-right: auto;

    margin-top: 8px;
    margin-bottom: 8px;

    text-align: justify;

    /* BODY */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    /* MAIN COLOR */
    color: var(--mainColor);
}