.Title {
    position: absolute;
    /*min-width: 197px;*/
    height: 38px;
    left: 133px;
    top: 82px;

    display: flex;
    align-items: center;
}

.Title > .ico {
    position: absolute;
}

.Title H3 {

    margin-left: 30px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    color: var(--mainColor);
}

.pointerCursor {
  cursor: pointer;
}

.mail {    
  position: absolute;
  width: calc(85% - 40px);
  /*min-height: 722px;*/
  height: auto;
  left: 135px;
  top: 144px;
}

.mailContent {
  position: relative;
    min-height: 412px;
    height: auto;

    min-width: 1096px;
    width: auto;

    background: var(--white);

    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px 15px 15px 15px;
}


/* ------------------------------------------------ */
 
.mailContentTab {
  position: absolute;
  width: 984px;

  left: 56px;
  top: 56px;  
}