/*
.displayContainer {
    display: grid;
    grid-template-columns: repeat(12, 88px);
    grid-gap: 24px;
}

.two {
    grid-column: 2;
    background-color: coral;
  }
  .three {
    grid-column: 3;
    background-color: coral;
  }
  .four {
    grid-column: 4;
    background-color: coral;
  }
  .five {
    grid-column: 5;
    background-color: coral;
  }
*/
.menuContainer {
  position: absolute;
  width: 24px;
  height: 450px;

  left: 27.5px; /* 55 */ /*27.5*/
  top: 108px;

  margin: 35px 25px 74px 25px;
  object-fit: contain;
}

.icoHome {
  position: absolute;
  left: 4.17%;
  right: 4.17%;
  top: 0%;
  bottom: 95.11%;
}

.ico2 {
  position: absolute;
  left: 8.33%;
  right: 11.11%;
  top: 12%;
  bottom: 84.78%;
}

.ico3 {
  position: absolute;
  left: 4.17%;
  right: 4.17%;
  top: 22.22%;
  bottom: 72.89%;
}

.ico4 {
  position: absolute;
  left: 12.5%;
  right: 16.67%;
  top: 34%;
  bottom: 62.67%;
}

.icoMore {
  position: absolute;
  left: 20.83%;
  right: 22.92%;
  top: 84.67%;
  bottom: 12.33%;
}

.icoHelp {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 94.67%;
  bottom: 0%;

  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden; /*hidden;*/

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 16px;

  background-color: #00a4bd;
  border-radius: 4px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  /*font-size: 14px;
    line-height: 18px;*/

  font-size: 0.889vw;
  line-height: 1.125rem;

  color: var(--white);
  text-align: center;
  white-space: nowrap;

  position: absolute;
  z-index: 100;
  bottom: 0;

  left: 33px;

  opacity: 0; /*0;*/
  transition: opacity 0.3s; /*0.3*/
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;

  top: 12px;
  left: -12px;

  border-width: 6px;
  border-style: solid;
  border-color: transparent #00a4bd transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.menuIco {
  width: 1vw;
  height: 1vw;

  min-width: 22px;
  min-height: 22px;
}

.menuDisabled {
  cursor: not-allowed;
}

@media only screen and (max-width: 1366px) {
  .tooltip .tooltiptext {
    font-size: 14px;
    line-height: 18px;
  }
}
