
.checkBox {
    display: flex;
    
    min-width: 132px; 
    height: 24px; 

    margin: 8px 24px 8px;
}

.avatar-checkBox {
    margin-left: 9px;

    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
}

.text-checkBox {
    margin-left: 7px;

    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* Third color */
    color: #748996;
}