@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

.contentNotifyPreview {
    background-image: url("../../../assets/images/NotifyPreview.png");
    background-color: var(--white);
    min-height: 720px; /* 720 */
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    border-radius: 15px;
    margin: auto;
    vertical-align: middle;
    
    clip-path: inset(0 0 50px 0);
    
}

.NotifyPrew {

    overflow: hidden;
    position: inherit;
    top: 120px;
    height: 400px;

    
}

.notificationPrewContent {

    background-color: rgba(216, 220, 224, 0.808);
    
    border-radius: 24px;

    position: inherit;
    margin: auto;

    overflow: hidden;

    word-wrap: break-word;
    height:auto;
    width: 45%;
    max-height: 80%;

    box-sizing: border-box;

    padding: 16px;

}

.notifyInfo {


    word-wrap: break-word;
    height:auto;
     
    margin: auto;
    width: 100%;
}

.titleNotify {   

    
    height: auto;

    padding: 5px;

    word-wrap: break-word;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: black;
}


.textNotify {       

    word-wrap: break-word;
    height:auto;

    padding: 5px;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    color: black;
}

.NotifyPrewAppInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;

    margin-bottom: 8px;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: rgb(70, 70, 70);
}

.NotifyPrewAppInfoName{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    gap: 8px;
}

.NotifyPrewAppInfoLogo{
    background-image: url("../../../assets/brand/logo.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    background-color: #ffffffb9;
    border-radius: 4px;
    width: 20px;
    height: 20px;
}

.NotifyPrewAppInfoLogoAura{
    background-image: url("../../../assets/brand/logoAura.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    background-color: #ffffffb9;
    border-radius: 4px;
    width: 20px;
    height: 20px;
}

.NotifyPrewAppInfoNow {
    right: 0px;
    font-size: 10px;
}

