@import url('https://fonts.googleapis.com/css2?family=Mulish');

.Content {
    left: 135px;
    width: 100%;
}

.headline {
    position: absolute;
    top: 80px;
    left: 133px;
}

.dataTitle {
    position: absolute;
    width: 302px;
    height: 40px;
    
    top: 0px;
}

.dataTitle H1 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--mainColor);
}

.dataSubTitle {
    position: absolute;
    width: 177px;
    height: 20px;

    top: 40px;
}

.dataSubTitle H3 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #748996;
}

.previewData {
    position: absolute;
    min-width: 700px;
    width: calc(80% - 40px);
    left: 130px;
    top: 162px;
    height: auto;
}

/* ----------------- */



