.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-1v4ccyo {
    width: 100%;
    min-width: 150px;
    max-width: 200px;
}

.modalConfigureUser { 

    position: relative;
    min-width: 424px;
    width: 50%;
    max-width: 700px;
    min-height: 496px;
    height: auto;

    display: flex;
    justify-content: center;
    align-content: center;

    background: var(--background);
    border: 1px solid var(--border2);

    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
    align-items: flex-end;
}

.modalEditWigdet {
    position: relative;
    width: auto;
    min-height: 496px;
    height: auto;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: var(--background);
    border: 1px solid var(--border2);
    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
    height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.modalDeleteWigdet {
    position: relative;
    width: 424px;
    min-height: 496px;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: var(--background);
    border: 1px solid var(--border2);
    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.modalConfigureAudience {

    position: relative;
    width: 536px;
    min-height: 200px;
    height: auto;

    display: flex;
    justify-content: center;
    align-content: center;

    background: var(--background);
    border: 1px solid var(--border2);

    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.contentInfoModalConfigureAudience {
    display: flex;
}

.contentModalConfigureUser {
    overflow-y: scroll;
    overflow-x: hidden;
    
    position: absolute;
    top: 56px;
    
    box-sizing: content-box;
    width: 415px; /*377*/
    height: 360px;   

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.modalConfigureUser > ::-webkit-scrollbar {
    width: 6px;    
}

.modalConfigureUser > ::-webkit-scrollbar-thumb {
    width: 6px;
    background: #D5D9DE;
    border-radius: 100px;    
}

.modalConfigureUser> ::-webkit-scrollbar-track {
    width: 6px;
    background: var(--white);
    border-radius: 100px;
}   

.lines {
    position: absolute;

    width: 100%;
    height: 1px;
    top: 46px;
    
    background-color: var(--border2);

    border: 1px solid var(--border2);
    box-sizing: border-box;
}

.textTitleModalConfigureUser {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 2.82%;
    bottom: 93.15%;

    /* H5/H5 MEDIUM */

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    /* MAIN COLOR */

    color: var(--mainColor);
}

.closeModalConfigureUser {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 368px;
    top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background: rgba(196, 202, 206, 0.2);
    border-radius: 5px;
}

.imageContentModalConfigureUser {
    background: #FFC3BA;

    border-radius: 50%;

    position: absolute;
    width: 56px;
    height: 56px;
    /*left: 184px;
    top: 64px;*/

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    /* identical to box height */


    color: #B36256;
}

.imageContentModalConfigureUser > img {

    width: 56px;
    height: 56px;
    object-fit: cover; /*contain*/
    border-radius: 50%;
}

.iconPlus {
    position: absolute;
    right: 0;
    bottom: 0;
}

.infoContentModalConfigureUser {
    margin-top: 20px;
    position: absolute;
    width: 377px; /*377*/
    height: 277px; /*277px*/
    /*left: 23px;*/
    top: 180px;

    margin-top: 32px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    grid-gap: 11px 24px;
}

.labelForm{   
    
    margin-bottom: 5px;

    /* SUBHEAD SMALL */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    
    /* Third color */
    color: #748996;
}

.half {
    width: 176px;    /* 176px; */ 
    height: 61px;
}

.twoHalf {
    width: 376px;    /* 376px; */
    height: 61px;
}
 
.inputContentModalConfigureUser {
    /* Rectangle 1 */
    box-sizing: border-box;

    width: auto;
    min-width: 176px;
    height: 40px;

    position: absolute;
    /*left: 5.66%;
    right: 5.66%;
    top: 45.16%;
    bottom: 46.77%;*/

    /* LINES BORDER CARD */
    border: 1px solid #C4CACE;
    border-radius: 6px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    padding-left: 15px;

    /* MAIN COLOR */
    color: var(--mainColor);
}

.inputContentModalConfigureUser:focus {
    outline: none;
}

.input_twoHalf {
    min-width: 376px;

}

.actionContentModalConfigureUser {   
    position: relative;
    bottom: 10px;
    margin: 5px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.separeComponents {
    display: flex;
    align-items: center;
    width: 100%;
}

.input2ContentModalConfigureUser {
    /* Rectangle 1 */
    box-sizing: border-box;
    width: auto;
    width: 90%;
    min-width: 50px;
    height: 40px;
    margin-right: 10px;
    /* LINES BORDER CARD */
    border: 1px solid #C4CACE;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    padding-left: 15px;
    /* MAIN COLOR */
    color: var(--mainColor);
}

.input2ContentModalConfigureUser:focus {
    outline: none;
}

.container2ContentModalConfigureUser {
    width: 50%;
}

.input3ContentModalConfigureUser {
    /* Rectangle 1 */
    box-sizing: border-box;
    height: 40px;
    /* LINES BORDER CARD */
    width: 100%;
    border: 1px solid #C4CACE;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    padding-left: 15px;
    /* MAIN COLOR */
    color: var(--mainColor);
}

.input3ContentModalConfigureUser:focus {
    outline: none;
}

.container3ContentModalConfigureUser {
    width: 100%;
}

.wrapper {
    width: 35px;
    position: relative;
  }
  
  .wrapper__select {
    width: 100%;
    opacity: 0;
  }
  
  .wrapper__flags {
    width: 100%;
    height: 100%;  
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  .wrapper__flags__flag {
    height: 100%;
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: none;
  }
  .wrapper__flags__flag.is-visible {
    display: block;
  }

  .rolesInfoContentModalConfigureUser {
    margin-bottom: 10px;
  }

  .contentAudiences {
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 60px;
    max-width: 90%;
  }

  .buttonActions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }