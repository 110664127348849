@import "../../assets/styles/index.scss";
.title {
  margin-left: 0.5%;
  text-align: left ;
  font-size: $FontSize18;
  line-height: $LineHeight32;
  letter-spacing: $LetterSpacing015;
  font-family: $FontFamily2;
}

.titleCard {
  margin-left: 0.5%;
  text-align: left !important ;
  font-size: $FontSize16 !important;
  line-height: $LineHeight24 !important;
  letter-spacing: $LetterSpacing02 !important;
  font-family: $FontFamily3 !important;
  font-weight: normal !important;
}