.ModalBackground {

    background: rgba(127, 127, 127, 0.6);

    position: fixed;
    /*width: 100%;
    height: 100%;*/   

    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 10;
}

.ModalForeground {

    position: fixed;
    /*width: 100%;
    height: 100%;*/

    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/

    z-index: 999;
}