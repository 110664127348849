@import "../../assets/styles/index.scss";

.containerFooterComponent {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 83%;
  margin-left: 15%;
}
.textComponentFooter {
  margin-left: 1.5%;
  margin-top: 0.5%;
  font-size: $FontSize12 !important;
  line-height: $LineHeight18 !important;
  letter-spacing: $LetterSpacing025 !important;
  font-family: $FontFamily3 !important;
  color: $BlackLow !important;

  a {
    color: $BlackLow !important;
  }
}

@media (max-width: 1240px) {
  .textComponentFooter {
    margin-left: 6.5%;
  }
}

@media (max-width: 960px) {
  .textComponentFooter {
    margin-left: 11.5%;
  }
}