@import url('https://fonts.googleapis.com/css2?family=Mulish');

/* -------------------- create widget from --------------- */
.contentsWidgetform {
    
    width: 472px;
    /*min-height: 622px;*/

    height: auto;
    margin: 0px 32px 0px 32px;

    padding-top: 16px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    /*background-color: aquamarine;*/
}

.WidgetformTitles {

    margin-top: 11px;
    margin-bottom: 11px;

    /* H4/H4 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    /* MAIN COLOR */
    color: var(--mainColor);
}

/* -------------------- date --------------- */

.date {
    /*position: absolute;        
    top: 74px;*/

    left: 31px;
    width: 472px;
    height: 30px;

    display: grid;
    grid-template-columns: 1fr, 1fr;

    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    /* Third color */
    color: #748996;
}

.startDate {
    grid-column: 1;

}

.finalDate {
    grid-column: 2;

}

/* -------------------- audience --------------- */
.audience {
    height: auto;
}

.select {
    display: grid;
    grid-template-columns: 1fr 48px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    background: #F5F5FD;
            
    width: 100%;
    min-height: 32px;   
    
    padding-top: 12px;
    padding-bottom: 12px;
        
    border-radius: 6px;

    display: flex;
}

.contentSelect > P {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;

    margin: 0px;
    
    /* Third color */
    color: #748996;
}

/* -------------------- dropdown --------------- */
.dropdown-content {
    display: none; /*none*/

    position: absolute;

    width: 336px;
    height: 304px;

    float: right;
    margin-top: 16px;
    margin-left: 136px;
   
    background: var(--white);

    /* BORDER 2 */
    border: 1px solid #EAEEEF;
    box-sizing: border-box;
    border-radius: 6px;

    z-index: 1;
}

.show { display: block; }

.open { transform: rotate(-180deg); }



/* -------------------- dropdown / buscador --------------- */

.audienceSearch {
    position: relative;
	
    width: 334px;
    height: 71px;    

    /* BACK 2 */
    background: #F5F5FD;
    border-radius: 5px 5px 0px 0px;

    display:flex;
    justify-content: center;
    align-items: center;
}

.audienceSearch input {
    width: 288px;
    height: 48px;

    /*left: 24px;
    width: 24px;*/
    

    background: #F5F5FD;

    border: 1px solid rgba(116, 137, 150, 0.15);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* Firefox 4+, Opera, Chrome */

    box-sizing: border-box;
    border-radius: 6px;
}

.inputSearch {
    padding-right: 46,83px;
    padding-left: 15px;
}


.audienceSearch > ::-webkit-input-placeholder { /* Edge */
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    /* identical to box height */
    color: rgba(116, 137, 150, 0.7);
}
  
.audienceSearch > :-ms-input-placeholder { /* Internet Explorer */
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    /* identical to box height */
    color: rgba(116, 137, 150, 0.7);
}
  
.audienceSearch > ::placeholder {
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    /* identical to box height */
    color: rgba(116, 137, 150, 0.7);
}

.audienceSearch input:focus {
    outline: none;

    padding-right: 46,83px;
    padding-left: 15px;

    color: var(--mainColor);

    border: 1px solid #748996;
    box-sizing: border-box;
    border-radius: 6px;
}

.audienceSearch > .icoSearch {
    position: absolute;
    right: 40.83px;
	top: 50%;
	transform: translateY(-50%);
}

.title > label {
    margin: 4px;
  
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
  
    /* MAIN COLOR */
    color: var(--mainColor);
  }

/* -------------------- dropdown / lista --------------- */
/*

.audienceList {
    height: 167px;
    overflow-y: scroll; /*scroll
    overflow-x: hidden;

    padding-top: 24px;
}

.dropdown-content > ::-webkit-scrollbar {
    width: 6px;
}

.dropdown-content > ::-webkit-scrollbar-thumb {
    width: 6px;
    background: #D5D9DE;
    border-radius: 100px
}

.dropdown-content > ::-webkit-scrollbar-track {
    width: 6px;
    background: var(--white);
    border-radius: 100px
}   

.lines {
    /* BORDER 2 
    background-color: #EAEEEF;

    border: 1px solid #EAEEEF;
    box-sizing: border-box;

    margin: 0;
}

.contentSelect {
    grid-area: 2 / 2;
    width: 400px;
    margin-left: 24px;

    display:flex;
    flex-wrap: wrap;

    align-items: center;
}

.actionDrop {
    grid-area: 2 / 3;
    width: 48px;
    

    cursor: pointer;

    /*background-color: red;

    display:flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    /*
    position: absolute;
    left: 95.47%;
    right: 1.44%;
    top: 64.29%;
    bottom: 8.93%;

    
    /* transform: rotate(-180deg); 
}


input[type=checkbox] {

    cursor: pointer;

    width: 13px;
    height: 13px;

    border: 1px solid #748996;
    box-sizing: border-box;
    border-radius: 2px;
}

.audienceSelectAll {
    margin: 0px 24px 24px 24px;

    display:flex;    
    align-items: center;
}

.optionDrop {
    width: 336px;
    min-height: 135px;
    
    display:flex;
    flex-direction: column;
}

.audienceAdd {
    height: 39px;

    margin-left: 24px;

    display:flex;
    align-items: center;
    flex-direction: row;    
}

.audienceAdd > .ico {
    margin-right: 7px;
}


.audienceAdd > H5{
    /* H5/H5 
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height 

    /* AUXILIAR COLOR 1 

    color: #00A4BD;
}

/* -------------------- message --------------- */

.message {    
    display:flex;
    flex-direction: column;
}

.editText {
    
    resize: none;
    /*height: auto;*/
    min-height: 104px;

    /*overflow: hidden;*/

    background: #F5F5FD;
    border-radius: 6px;
    border-color: transparent;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    /* Third color */
    color: #748996;
}

.editText input:focus {

    background: #F5F5FD;
    border-radius: 6px;
    border-color: transparent;

}

.actionText {
    display:flex;
    flex-direction: row;
    column-gap: 16px;
    margin-top: 8px;
}

.fontStyle {
    width: 32px;
    height: 32px;

    cursor: pointer;

    /*font-weigth: bold;*/

    display:flex;
    justify-content: center;
    align-items: center;

    background: #F5F5FD;
    border-radius: 5.25926px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;

    /* Third color */
    color: #748996;
    
}

.listAttachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 32px;

    gap: 18px 15.5px; 

    align-items: center;
    align-content: center;
}

.attachments {
    display:flex;
    justify-content: center;
    align-items: center
}

.contentAttachments {
    
    display:flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    flex-direction: column;

    width: 228.12px;
    height: 104px;

    margin: 15.88px;

    border: 1px dashed #748996;
    box-sizing: border-box;
    border-radius: 5px;

    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    /* Third color */
    color: #748996;

    display:flex;
    justify-content: center;
    align-items: center;
}

.actions {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.attach {
    background: #F5F5FD;
    border-radius: 6px;
    
    cursor: pointer;

    margin: 8px;

    width: 48px;
    height: 48px;
    
    display:flex;
    justify-content: center;
    align-items: center;
}
.notify {
    background: #F5F5FD;
    border-radius: 6px;

    cursor: pointer;

    margin: 8px;

    width: 48px;
    height: 48px;

    display:flex;
    justify-content: center;
    align-items: center;
}
.schedule {
    width: 127px;
    height: 48px;
    /* Auto layout 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /*padding: 14px 20px 5px;*/

    cursor: pointer;
    margin: 8px;

    display:flex;
    justify-content: center;
    align-items: center;

    /* SECOND COLOR */
    border: 1px solid #FF8614;
    box-sizing: border-box;
    border-radius: 6px;

    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FF8614;
}
.actionsWidget {
    margin-top: 12px;
    display: flex;
    height: 40px;
    min-width: 50px;
    justify-content: end;
}

.toWidget {
    width: 127px;
    height: 48px;
    cursor: pointer;
    margin: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    /* Second color */
    background: #FF8614;
    border-radius: 6px;
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--white);
}

.toWidgetDisabled {
    width: 127px;
    height: 48px;
    cursor: pointer;
    margin: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    background: gray;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--white);
    pointer-events: none;
}

.actionsWidgetNotAllowed {
    cursor: not-allowed;
    margin-top: 12px;
    display: flex;
    height: 40px;
    min-width: 50px;
    justify-content: end;
}


/* tooltip */


.widgetTooltip .tooltiptext {

    visibility: hidden; /*hidden; ---*/

    display:flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 16px;

    background-color: #00A4BD; 
    border-radius: 4px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */


    color: var(--white);
    text-align: center;
    white-space: nowrap;
    
    position: absolute;
    z-index: 1;
    top: 101%;
    

    /*left: 33px;*/
    
    opacity: 0; /*0; --- */
    transition: opacity 0.3s; /*0.3*/
}
  
.widgetTooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #00A4BD transparent;
}
  
.widgetTooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

