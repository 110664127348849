/* * {box-sizing: border-box;} */

.header {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  /*bottom: 12.33%;*/

  overflow: hidden;
  /*padding: 20px 10px;*/

  width: 100%;
  min-width: 1231px;
  height: 64px;

  border: solid 1px rgba(116, 137, 150, 0.15);
  background-color: #fff;

  box-sizing: border-box;
}

.header-right {
  position: absolute;
  right: 33px;
  top: 21.92%;
  bottom: 34.25%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}

.header-left {
  float: left;

  width: 3px;
  height: 30px;

  position: absolute;
  /*left: 4.54%;
  right: 93.3%;*/

  left: 51px;

  top: 23.29%;
  bottom: 35.62%;

  flex-grow: 0;
}

.changeLanguage {
  cursor: pointer;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--mainColor);
}

.changeLanguage::after {
  content: "";
  display: block;
  background: url("../../../assets/icons/navigation/changeLanguage.svg")
    no-repeat;
  width: 8px;
  height: 5px;
  float: right;
  margin: 8px 8px 8px 8px;
}

.userProfile {
  cursor: pointer;
  width: 31px;
  height: 32px;
  background: rgba(255, 134, 20, 0.2);
  border-radius: 5px;
}

.nameCommunity {
  margin: 0px 10px;
  cursor: pointer;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: var(--mainColor);
}
.profileDropdown-content {
  position: relative;
  width: 250px;
  left: calc(90% - 100px);
  top: 66px;
  box-sizing: border-box;
  background: var(--white);
  border: 1px solid #eff1f8;
  border-radius: 6px;
  z-index: 1!important;
  overflow-y: scroll;
  /* height: fit-content; */
  max-height: 75vh;
  overflow-x: hidden;
  margin-bottom: 100px;
}

.profileDropdownItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.profileDropdownItem:hover {
  filter: opacity(50%);
}

.containerComm {
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  height: auto;
}

.itemProfile, .itemComm {
  cursor: pointer;
    margin: 10px;
    display: flex;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--mainColor);
    width: 100%;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
}

.profileCommunityItem {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  cursor: pointer;
  margin: 5px;
  margin-left: 20px;
}

.profileCommunityItem:hover {
  filter: opacity(50%);
}

.profileLogout {
  bottom: 0px;
}

.bold {
  font-weight: bolder;
}

/* media queries for responsiveness */
/*
@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}
*/


.containerSearchHeader {
  position: relative;
  height: 71px;
  background: white;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputSearchHeader {
  padding-left: 25px;
}

.containerSearchHeader input {
  width: 220px;
  height: 48px;
  /*left: 24px;
  width: 24px;*/    
  background: var(--back2);
  border: 1px solid rgba(116, 137, 150, 0.15);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* Firefox 4+, Opera, Chrome */
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--mainColor);
}

.containerSearchHeader > ::-webkit-input-placeholder { /* Edge */
  /* H6/H6 */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: rgba(116, 137, 150, 0.7);
}

.containerSearchHeader > :-ms-input-placeholder { /* Internet Explorer */
  /* H6/H6 */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: rgba(116, 137, 150, 0.7);
}

.containerSearchHeader > ::placeholder {
  /* H6/H6 */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: rgba(116, 137, 150, 0.7);
}

.containerSearchHeader input:focus {
  outline: none;
  padding-right: 46,83px;
  padding-left: 25px;
  color: var(--mainColor);
  border: 1px solid var(--thirdColor);
  box-sizing: border-box;
  border-radius: 6px;
}

.containerSearchHeader > .icoSearch {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
