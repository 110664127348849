
/* --- --- --- Filled --- --- ---- */
.disableButton > button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    padding: 0px 20px;
    background: gray;
    border: 1px solid gray;
    border-radius: 6px;
    text-decoration: none;
    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--background);
}

.noClick {
    pointer-events: none;
}

.containerSecondaryButton > button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    padding: 0px 20px;
    background: var(--secondColor);
    border: 1px solid var(--secondColor);
    border-radius: 6px;
    text-decoration: none;
    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--background);
}

.containerSecondaryButton > button.a {
    text-decoration: none;
}

.containerSecondaryButton > button:hover {
    background: var(--hoverButton);
    border: 1px solid var(--hoverButton);
    border-radius: 6px;
}

.containerSecondaryButton > button:disabled, button[disabled] {
    
    background: var(--thirdColor);
    border: 1px solid var(--thirdColor);
    border-radius: 6px;
}

/* --- --- --- Outline --- --- ---- */

.containerOutlineSecondaryButton > button {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    height: 40px;
    padding: 0px 20px;

    background-color:rgba(0, 0, 0, 0.0);

    border: 1px solid var(--secondColor);
    border-radius: 6px;

    text-decoration: none;

    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: var(--secondColor);
}

.containerOutlineSecondaryButton > button:hover {
    border: 1px solid var(--hoverButton);
    border-radius: 6px;
    
    color: var(--hoverButton);
}

/* --- --- --- Danger --- --- ---- */

.containerDangerSecondaryButton > button {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    height: 40px;
    padding: 0px 20px;

    background-color: var(--systemError);

    border-style: none;
    border-radius: 6px;

    text-decoration: none;

    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: var(--background);
}

.containerDarkSecondaryButton {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    height: 40px;
    padding: 0px 20px;

    background-color: var(--systemError);
    background-color: gray;

    border-style: none;
    border-radius: 6px;

    text-decoration: none;

    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: var(--background);
}
/*
.containerOutlineSecondaryButton > button:hover {
    border: 1px solid var(--hoverButton);
    border-radius: 6px;
    
    color: var(--hoverButton);
}
*/

.buttonCommunityTooltip .tooltiptext {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 16px;
    background-color: #00A4BD;
    border-radius: 4px;
    font-family: 'Mulish';
    font-size: 14px;
    color: var(--white);
    position: absolute;
    top: 50px;
    right: 15%;
    transition: opacity 0.3s;
    min-width: 120px;
    width: auto;
}
  
.buttonCommunityTooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    right: 60px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #00A4BD transparent;
}
  
.buttonCommunityTooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}