.modalContact {

    position: relative;
    width: 424px;
    height: 199px;

    display: flex;
    justify-content: center;
    align-content: center;

    background: var(--background);
    border: 1px solid var(--border2);

    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.contentModalContact {
    
    position: absolute;
    top: 56px;
    
    box-sizing: content-box;
    width: 415px; /*377*/
    height: 360px;   

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
 

.lines {
    position: absolute;

    width: 100%;
    height: 1px;
    top: 46px;
    
    background-color: var(--border2);

    border: 1px solid var(--border2);
    box-sizing: border-box;
}

.textTitleModalContact {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 7%;
    bottom: 93.15%;

    /* H5/H5 MEDIUM */

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    /* MAIN COLOR */

    color: var(--mainColor);
}

.contentModalContact {

    width: 80%;

    /* H5/H5 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    /* MAIN COLOR */

    color: var(--mainColor);
}

.closeModalContact {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 368px;
    top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background: rgba(196, 202, 206, 0.2);
    border-radius: 5px;
}

.actionContentModalContact {   

    position: absolute;

    display: flex;
    flex-direction: row;
    gap: 16px;
    
    height: 40px;
    bottom: 16px;
}

.txtMailto a:link{    
    text-decoration: none;
}

a {
    cursor: pointer;
    text-decoration: none;
}