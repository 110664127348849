@import "../../../assets/styles/index.scss";

#gjs {
  border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}
.panel__basic-actions {
  position: initial;
}

.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 300px;
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
}

.panel__switcher {
  position: initial;
}

.panel__devices {
  position: initial;
  margin-left: 130px;
}



#gjs {
  background-color: $WhiteBase;
  border: 1px solid #d1d1d1;
  min-height: 683px !important;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block {
  min-height: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 60px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}
.panel__basic-actions {
  position: initial;
}

.editor-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: auto;
}

.editor-canvas {
  flex-grow: 1;
  min-height: 600px;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
  border: 1px solid #d1d1d1;
  background-color: $WhiteBase;
}

.panel__switcher {
  position: initial;
}

.panel__devices {
  position: initial;
}

/* Theming */

/* Primary color for the background */
.gjs-one-bg {
  background-color: $WhiteBase;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: $BlackHigh;
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: $BlackHigh;
  color: $WhiteBase;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: $BlackHigh;
}

.dFlex {
  display: flex;
  background: white;
  justify-content: flex-end;
  align-items: center;
}

#igr3,
#igr3-2,
#ikt8,
#ivxq,
#iek1,
#ix6w,
#ivxq-2,
#ix55h,
#i0cl,
#iq3m,
#i5a9,
#i3bf,
#ivxq-3,
#ivxq-4,
#ivxq-5,
#iy8e6,
#igr3-3,
#igr3-4,
#igr3-5,
#iek1-2 {
  border: 1px dotted gray;
}

#i2ws,
.contentTitleDefault {
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px dotted gray;
  margin: 15px;
  padding: 10px;
}

#imoh,
.titleDefault {
  font-family: "Arial";
  font-size: 35px;
  display: flex;
  justify-content: center;
}

.fa {
  font: normal normal normal 14px/1 FontAwesome; // to show all icons. These icons appear when you press the block
}

.gjs-block-categories {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
}

gjs-layer {
  display: none !important;
}

#blocks {
  max-width: 215px !important;
  min-width: 215px !important;
}

.gjs-editor-cont .sp-container {
  position: absolute;
  top: 35% !important;
  left: 75%!important;
}

.gjs-rte-action:last-child {
  display: none;
}

.gjs-field-arrow-u, .gjs-field-arrow-d {
  width: 3px;
}