@import url('https://fonts.googleapis.com/css2?family=Mulish');

.Content {
    left: 135px;
}

.headline {
    position: absolute;
    top: 80px;
    left: 135px;
}

.communityTitle {
    position: absolute;
    width: 353px;
    height: 40px;
    
    top: 0px;
}

.communityTitle H1 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--mainColor);
}

.communitySubTitle {
    position: absolute;
    width: 177px;
    height: 20px;

    top: 40px;
}

.communitySubTitle H3 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #748996;
}

.communityActions {
    position: absolute;     
    min-width: 1096px;    
    left: 135px;    
    top: 88px;
    width: 90vw;
    justify-content: center;
}

@media (min-width: 1360px) {
    .communityActions {
        /*width: 82%;*/
    }
}

@media (min-width: 1920px) {
    .communityActions {
        width: calc(100vw * 0.905);
    }
}

.community {    
    position: absolute;
    width: 100%;
    /*min-height: 722px;*/
    height: 70%;
    left: 135px;
    top: 192px;
}


.communityContent {

    position: relative;
    /* min-height: 412px; */
    height: calc(100vh * 0.65);
    width: 99%;
    min-width: 1096px;
    min-height: 455px;
    background: var(--white);
    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px 15px 15px 15px;
    display: flex;
    justify-content: center;
}

.cursorPointer {
    cursor: pointer;
}


@media (min-width: 1360px) {
    .community {
        width: 82%;
        height: 68%;
    }
    .communityContent {
        height: calc(100vh * 0.62);
        width: 100%;
    }
}


@media (min-width: 1920px) {
    .community {
        width: calc(100vw * 0.905);
        height: calc(100vh * 0.7);
    }
    .communityContent {
        height: calc(100vh * 0.65);
        width: 100%;
    }
}
