
.selectAudienceRadio {
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    gap: 16px;
}

.selectAudienceRadio input[type="radio"]:checked+label {
    background-color: var(--back2);
    border-radius: 15px 0 0 15px;
  }

.selectAudienceRadio label {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;    

    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.266rem;
    /* identical to box height */

    /* Third color */
    color: var(--mainColor);
}

.txt-NOTenabled {
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 0.790rem;
    line-height: 1rem;
    /* identical to box height */

    /* Third color */
    color: var(--thirdColor);
}

.audienceOption {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    gap: 8px;
}

.audienceOptionLabel {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    gap: 8px;
}