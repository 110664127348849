.modalDeleteUser {

    position: relative;
    width: 424px;
    min-height: 200px;

    display: flex;
    justify-content: center;
    align-content: center;

    background: var(--background);
    border: 1px solid var(--border2);

    /* DROP SHADOW */
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.modalImportUsers { 
    position: absolute;
    width: 424px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
    background: var(--background);
    border: 1px solid var(--border2);
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contentModalDeleteUser {
    top: 56px;
    box-sizing: content-box;
    width: 415px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--mainColor);
    justify-content: center;
}
 

.lines {
    position: absolute;

    width: 100%;
    height: 1px;
    top: 46px;
    
    background-color: var(--border2);

    border: 1px solid var(--border2);
    box-sizing: border-box;
}

.textTitleModalDeleteUser {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 7%;
    bottom: 93.15%;

    /* H5/H5 MEDIUM */

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    font-weight: bold;

    /* MAIN COLOR */

    color: var(--mainColor);
}

.closeModalDeleteUser {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 368px;
    top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background: rgba(196, 202, 206, 0.2);
    border-radius: 5px;
}

.actionContentModalDeleteUser {   

    position: absolute;

    display: flex;
    flex-direction: row;
    gap: 16px;
    
    height: 40px;
    bottom: 16px;
}

/* ------------------------------------- */

.modalContainerCantCreate {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    overflow: hidden;
    align-items: stretch;
    width: 938px;
    height: 592px;
    background: var(--background);
    border: 1px solid var(--border2);
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.modalContainerLinearProgress {
    box-sizing: border-box;
    max-width: 50%;
    max-height: 20%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: 938px;
    height: 592px;
    background: var(--background);
    border: 1px solid var(--border2);
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
    padding: 15px;
}

.MuiLinearProgress-colorPrimary {
    color: rebeccapurple;
}

.containerLeft {
    position: relative;
    flex: 0 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    align-items: center;
    padding: 30px;
}

.containerRigth {
    flex: 1;
    overflow: hidden;
    position: relative;
    position: relative;
    background: rgba(196, 202, 206, 0.2);
    height: 100%;
    overflow: hidden;
    z-index: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerRigth img {
    width: 100%;
    max-height: 350px;
    object-fit: contain;
}

.closeModalCantCreate {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(134, 136, 137, 0.2);
    border-radius: 5px;
}


.buttonCantCreate {
    display: flex;
    position: absolute;
    bottom: 80px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.titleCantCreate {
    display: flex;
    align-items: baseline;
}

.titleCantCreate h3 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--mainColor);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.bodyCantCreate p {
    font-family: 'Mulish';
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    color: var(--mainColor);
}

.pBold {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: var(--mainColor);
}

.containerItemsCantCreate {
    display: flex;
    flex-direction: column;
}

.containerItemsCantCreate p {
    display: flex;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 23px;
    color: var(--mainColor);
    margin: 5px;
}

.containerItemsCantCreate .iconCantCreate {
    margin-right: 15px;
    color: var(--secondColor);
}

.buttonCantCreate > button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    padding: 0px 20px;
    background-color: #FF8614;
    border: 1px solid #FF8614;
    border-radius: 6px;
    text-decoration: none;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: white;
    min-width: 280px;
}

.buttonCantCreate > button:hover {
    border: 1px solid var(--hoverButton);
    border-radius: 6px;
    color: white;
}