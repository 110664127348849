@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

.contentLinkPreview {
    background-image: url("../../../assets/images/LinkPreview.png");
    background-color: var(--white);
    min-height: 720px; /* 720 */
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    border-radius: 15px;
    margin: auto;
    vertical-align: middle;
    
    clip-path: inset(0 0 50px 0);
    
}

.linkedin {
    overflow: hidden;

    position: inherit;
    margin: auto;

    top: 90px;

    /*vertical-align: bottom;

    top: 82px;*/

    
    height: 450px;
    /*width: 270px;*/
    width: 50%;

 
}

.linkedinContent {

    /*background-color: red;*/

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    
    position:absolute;
    /*padding: 5px;*/
    vertical-align: middle;
    /*width: 260px;*/

    width: 100%;

    top: 0px;
    /*bottom:0px;*/

    
}

.link {

    background-color: var(--white);
    
    border-radius: 5px;

    word-wrap: break-word;
    height:auto;
     
    margin: auto;
    width: 100%;
}

.titleLink {
    width: 100%;
    min-height:41px;
    height: auto;
    background-image: url("../../../assets/images/titleLink.png");
    background-color: var(--white);
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 8px;
}

.titleLinkAura {
    width: 100%;
    min-height:41px;
    height: auto;
    background-image: url("../../../assets/images/titleLinkAura.png");
    background-color: var(--white);
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 8px;
}

.titleTextLink {

    padding-left: 25%;
    padding-right: 5%;

    word-wrap: break-word;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.textLink {

    

    word-wrap: break-word;
    height:auto;

    padding: 10px;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    color: black;
}

.imageLink {
    width: 208px;
    
    width: 100%;
}

.socialMediaActions {

    word-wrap: break-word;

    width: 100%;
    height: 50px;

    border-radius: 4px;

    /*height: 102
    height: auto;*/

    background-image: url("../../../assets/images/socialLink.png");
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

