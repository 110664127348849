.modalAddColumn {
  position: relative;
  width: 424px;
  height: 496px;

  display: flex;
  justify-content: center;
  align-content: center;

  background: var(--background);
  border: 1px solid var(--border2);

  /* DROP SHADOW */
  box-shadow: -8px 4px 45px var(--shadow);
  border-radius: 6px;
}

.contentModalAddColumn {
  overflow-y: scroll;
  overflow-x: hidden;

  position: absolute;
  top: 70px;

  box-sizing: content-box;
  width: 415px; /*377*/
  height: 360px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.modalAddColumn > ::-webkit-scrollbar {
  width: 6px;
}

.modalAddColumn > ::-webkit-scrollbar-thumb {
  width: 6px;
  background: #d5d9de;
  border-radius: 100px;
}

.modalAddColumn > ::-webkit-scrollbar-track {
  width: 6px;
  background: var(--white);
  border-radius: 100px;
}

.lines {
  position: absolute;

  width: 100%;
  height: 1px;
  top: 46px;

  background-color: var(--border2);

  border: 1px solid var(--border2);
  box-sizing: border-box;
}
/*
.titleModalAddColumn {
  /
}
*/
.textTitleModalAddColumn {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 2.82%;
  bottom: 93.15%;

  /* H5/H5 MEDIUM */

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  /* MAIN COLOR */

  color: var(--mainColor);
}

.closeModalAddColumn {
  position: absolute;
  width: 32px;
  height: 32px;
  left: 368px;
  top: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: rgba(196, 202, 206, 0.2);
  border-radius: 5px;
}

.imageContentModalAddColumn {
  background: #ffc3ba;

  border-radius: 50%;

  position: absolute;
  width: 56px;
  height: 56px;
  /*left: 184px;
    top: 64px;*/

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height */

  color: #b36256;
}

.imageContentModalAddColumn > img {
  width: 56px;
  height: 56px;

  object-fit: cover; /*contain*/

  border-radius: 50%;
}

.infoContentModalAddColumn {
  position: absolute;
  width: 377px; /*377*/
  /*height: 277px; /*277px*/
  /*left: 23px;*/
  /*    top: 80px;*/

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;

  grid-gap: 11px 24px;
}

.labelForm {
  margin-bottom: 5px;

  /* SUBHEAD SMALL */
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;

  /* Third color */
  color: #748996;
}

.statusNoFound {
  margin: 8px;
  margin-top: 16px;

  color: var(--systemError);
}

.half {
  width: 176px; /* 176px; */
  height: 61px;
}

.twoHalf {
  width: 376px; /* 376px; */
  height: 61px;
}

.inputContentModalAddColumn {
  /* Rectangle 1 */
  box-sizing: border-box;

  width: auto;
  min-width: 176px;
  height: 40px;

  position: absolute;
  /*left: 5.66%;
    right: 5.66%;
    top: 45.16%;
    bottom: 46.77%;*/

  /* LINES BORDER CARD */
  border: 1px solid #c4cace;
  border-radius: 6px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  padding-left: 15px;

  /* MAIN COLOR */
  color: var(--mainColor);
}

.inputContentModalAddColumn:focus {
  outline: none;
}

.input_twoHalf {
  min-width: 376px;
}

.actionContentModalAddColumn {
  position: absolute;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  gap: 8px;

  /*height: 40px;*/

  left: auto;
  right: 24px;
  bottom: 16px;
}
/*
.selectFilter select option {
    background-color: red;
    max-width: 86px;
    min-height: 8px;
}
*/

.queryItem > input {
  margin: 4px 4px;

  /*margin-left: 8px;*/
}

.status > input {
    margin: 8px 4px;
  
    margin-left: 8px;
  }

.queryItem > label {
  margin: 4px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  /* MAIN COLOR */
  color: var(--mainColor);
}

.queryItem > textarea {
  resize: none;
  overflow: hidden;
  height: 60px;
}

.loadAudience {
  box-sizing: border-box;  
  text-align: center;
  margin: 16px;  
}