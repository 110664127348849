@import url('https://fonts.googleapis.com/css2?family=Mulish');

.previewDataImportUsers {
    position: relative;
    min-width: 700px;
    width: calc(84% - 40px);
    left: 130px;
    top: 125px;
}

.containerImportUsersFiles {
    min-height: calc(100vh - 130px);
    width: 100%;
    height: auto;
    background: var(--white);
    box-shadow: -8px 16px 50px rgb(112 144 176 / 10%);
    border-radius: 15px;
    padding: 20px;
}

.containerImportUsers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: auto;
}

.containerColumn {
    width: 250px;
    max-width: 250px;
    background: var(--white);
    box-shadow: -8px 16px 50px rgb(112 144 176 / 10%);
    border-radius: 15px;
    border: 1px solid gray;
    min-height: 300px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    max-height: 250px;
    overflow: hidden;
}

.containerColumn h4 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    color: white;
    background-color: var(--mainColor);
    text-align: center;
}

.containerSelected {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    cursor: pointer;
}

.CloseItemSelected {
    position: relative;
    right: 28px;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
}

.valueSelected {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    color: white;
    background-color: var(--mainColor);
    text-align: center;
    min-width: 220px;
    margin-left: 10px;
}

.containerColumn ul {
    list-style:none;
    font-family: 'Mulish';
    font-style: normal;
    font-size: 15px;
    color: var(--mainColor);
    text-align: center;
    text-decoration: none;
    padding: 0px;
}


.centerFlex {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.centerFlex h4 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    color: var(--mainColor);
    margin: 0px;
    padding-bottom: 30px;
}

.lastContainer {
    width: 100%;
    height: 60vh;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.lastContainer h4 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    color: var(--mainColor);
    margin: 0px;
    padding-bottom: 30px;
}

.containerImportUsersFile {
    cursor: pointer;
    flex-direction: column;
    width: 80%;
    height: 60%;
    margin: 15.88px;
    border: 1px dashed #0782d0;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    color: #0782d0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputSelectOptionsImporUser {
    box-sizing: border-box;
    width: auto;
    min-width: 176px;
    height: 40px;
    border: 1px solid #C4CACE;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-left: 15px;
    color: var(--mainColor);
    text-align: center;
}

.inputSelectOptionsImporUser:focus {
    outline: none;
}

.tabsImportUsers {
    width: 100%;
    margin: 15px;
}

.userTableImportUsers {
    height: 280px;
    background: var(--white);
    box-shadow: -8px 16px 50px rgb(112 144 176 / 10%);
    border-radius: 15px 15px 15px 15px;
    display: flex;
    justify-content: center;
    overflow: auto;
}

.containerRolesImportUser {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.contentImportUsersSelect {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 65px;
    min-width: 180px;
}

.contentleftImportUsersSelect {
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 65px;
}

.leftFlex {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    /* height: 60vh; */
}


.tableRow {
    vertical-align: middle !important;
    display: flex !important;
    justify-content: space-around !important;
    align-content: center !important;
    align-items: center !important;
} 

.tableRowHeader {
    display: flex !important;
    justify-content: space-around !important;
}

.MuiTableCell-head {
    min-width: 160px;
}

tr > .MuiTableCell-body {
    min-width: 160px;
    border-bottom: 1px none !important;
}

.tableRol {
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tableHeadRol {
    color: #ff9532;
    font-size: 16px;
    font-family: "DMSans-Bold";
    background-color: white;
    width: 122px;
}

.tableContainerBodyRol {
    width: 375px;
    border-bottom: 1px solid #bfc1c7;
    margin: 10px;
    height: 35px;
    display: flex;
    align-items: center;
}

.tableBodyRol {
    color: #6c707e;
    font-size: 14px;
    font-family: "DMSans-Regular";
    width: 120px;
    margin: 10px;
}

/* td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    min-width: 200px;
  } */
/*   
  tr:nth-child(even) {
    background-color: #dddddd;
  } */