.widgetListContainer {
  /* position: absolute; */
  top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px 24px;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.widgetsContainer {
  background-color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.widgetsActions {
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* H2 */
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  /* MAIN COLOR */
  align-items: center;
  color: #183e55;
}
.widgetsIcon {
  width: 200px;
  height: 200px;
  border-radius: 5%;
}

/* Dots */
.showWidgetActions {
  display: block !important;
}
.dropWidgetAction {
  display: none; /*none*/
  position: absolute;
  width: 167px;
  height: 88px;
  margin-left: 180px;
  top: 20px;
  right: 0px;
  background: var(--white);
  border: 1px solid #c4cace;
  border-radius: 6px;
  z-index: 1;
}
.dropWidgetAction-content {
  position: absolute;
  width: 167px;
  height: 88px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
}

.itemDropColumnAction {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 7px;
  margin-left: 12px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #748996;
}

/* Actions */

.deleteTitle {
  font-size: 25px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  color: var(--mainColor);
}

.deleteContent {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  color: var(--mainColor);
}

.containerSavePositionButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 10px;
}

.checkBoxOrerItems {
  padding-top: 10px;
  padding-left: 15px;
  color: var(--fourthColor);
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerCopyLink {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.containerCopyLink span {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  padding: 7px 15px;
  background-color: #00A4BD;
  border-radius: 4px;
  font-family: 'Mulish';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--white);
  text-align: center;
  position: absolute;
  top: 55px;
  width: 200px;
}

.containerCopyLink:hover span {
  visibility: visible;
}

.containerCopyLink h6 {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  padding: 7px 15px;
  background-color: #00A4BD;
  border-radius: 4px;
  font-family: 'Mulish';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--white);
  text-align: center;
  position: absolute;
  top: 55px;
  width: 200px;
  z-index: 100;
}

.containerCopyLink:hover h6 {
  visibility: visible;
}

.p-8 {
  padding: 0px 30px;
}