.columnContainer {
  /*width: 367px;*/
  min-height: 215px;
  height: auto;

  bottom: 0px;
}
/*
.columnContainer:focus-within {
  
  border-radius: 15px 15px 0px 0px;
  box-sizing: border-box;
  
}
*/
.notContent {
  margin-top: 38px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  /* BODY */
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* MAIN COLOR */
  color: var(--mainColor);
}

.loadMore {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;

  height: 350px;

  text-align: center;

  /* BODY */
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* MAIN COLOR */
  color: var(--mainColor);
}

.load {
  
  margin: 16px;

  width: 80%;

  box-sizing: border-box;

  text-align: center;

  color: var(--mainColor);
}

.columnHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  /*position: absolute;*/
  width: 366px;
  height: 50px;
  /*left: 500px;
    top: 208px;*/
  border-radius: 15px 15px 0px 0px;

  /* WHITE */
  background: var(--white);

  /* BORDER 2 */
  border: 1px solid #e4eaee;
}

.columnIco {
  margin-left: 16px;

  width: 24px;
  height: 24px;

  /* AUX COLOR/AUX COLO */
  background: #8188ce;
  border-radius: 3px;
}

.columnTitle {
  margin-left: 12px;
  /* H4/H4 MEDIUM */

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  /* MAIN COLOR */

  color: var(--mainColor);
}

.rightColumnHeader {
  margin-left: auto;
  margin-right: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  gap: 8px;
}

.columnInfo {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height */

  /* MAIN COLOR */

  color: #748996;
}

.columnAction {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  width: 13px;
  height: 13px;
}

.dropColumnAction{
  display: none; /*none*/
  
  position: absolute;
  width: 167px;
  height: 88px;

  margin-left: 180px;
  top: 40px;

  background: var(--white);
  /* LINES BORDER CARD */

  border: 1px solid #c4cace;
  border-radius: 6px;

  z-index: 1;

}
.dropColumnAction-content {
  position: absolute;
  width: 167px;
  height: 88px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;  
}

.itemDropColumnAction {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  gap: 7px;

  margin-left: 12px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* Third color */

  color: #748996;
}

.columnBody {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  height: 60vh;

  /*height: 60.82317vh;*/
  /*height: auto;*/
  min-height: 215px;

  bottom: 0px;

  width: 366px;

  word-wrap: break-word;
  overflow: auto;
  overflow-y: scroll;
  /*overflow-x: hidden;*/
}

.columnBody::-webkit-scrollbar {
  width: 6px;
}

.columnBody::-webkit-scrollbar-thumb {
  width: 6px;
  background: #d5d9de;
  border-radius: 100px;
}

.columnBody::-webkit-scrollbar-track {
  width: 6px;
  background: var(--white);
  border-radius: 100px;
}
