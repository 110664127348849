
.checkBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 24px 8px;
}

.user-container {
  display: flex;
}

.perfil-container {
  display: flex;
  flex-direction: column;
}

.name-perfil {
  margin-left: 9px;

  /* H6/H6 MEDIUM */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
}

.email-perfil {
  margin-left: 9px;

  /* H6/H6 MEDIUM */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
}
