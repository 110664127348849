.systemNotification {
    position: absolute;
    top: 95px;
    right: 23px;

    width: 312px;
    height: auto;
    min-height: 120px;

    padding-bottom: 64px;
    border-radius: 6px;    
}

.systemNotification2 {
    position: absolute;
    top: -220px;
    right: -180px;
    width: 312px;
    height: auto;
    min-height: 120px;
    padding-bottom: 64px;
    border-radius: 6px;    
}


.systemSuccess {    background: var(--systemSuccess);   }
.systemWarning {    background: var(--systemWarning);   }
.systemError   {    background: var(--systemError);     }

.notificationTitle {
    position: absolute;
    left: 17.95%;
    right: 70.19%;
    top: 22.22%;
    bottom: 50%;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;    

    box-sizing: border-box;
    
    color: var(--background);
}

.notificationCaption {
    position: absolute;
    left: 17.95%;
    right: 20.51%;
    top: 50%;
    bottom: 25%;

    height: auto;
    
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    color: var(--background);
}

.icoNotification {
    position: absolute;
    width: 24px;
    height: 24px;

    top: 23px;
    left: 17px;
}

.closeNotification {    
    position: absolute;
    left: 87.18%;
    right: 2.56%;
    top: 11.11%;
    bottom: 44.44%;

    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(255, 255, 255, 0.14);
    border-radius: 5px;
}
