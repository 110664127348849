@import url('https://fonts.googleapis.com/css2?family=Mulish');

/* -------------------- audience --------------- */
.audience {
    height: auto;
}

.select {
    display: grid;
    grid-template-columns: 1fr 48px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    background: var(--back2);
            
    width: 472.5px;
    min-height: 32px;   
    
    padding-top: 12px;
    padding-bottom: 12px;
        
    border-radius: 6px;

    display: flex;
}

.contentSelect > P {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;

    margin: 0px;
    
    /* Third color */
    color: var(--thirdColor);
}

/* -------------------- dropdown --------------- */
.mediumDropdown-content {
    display: none; /*none*/

    position: absolute;

    width: 336px;
    height: 304px;

    float: right;
    margin-top: 16px;
    margin-left: 136px;
   
    background: var(--white);

    /* BORDER 2 */
    border: 1px solid #EAEEEF;
    box-sizing: border-box;
    border-radius: 6px;

    z-index: 1;
}

.show { display: block; }

.open { transform: rotate(-180deg); }

/* -------------------- dropdown / buscador --------------- */

.audienceSearch {
    position: relative;
	
    width: 334px;
    height: 71px;    

    /* BACK 2 */
    background: var(--back2);
    border-radius: 5px 5px 0px 0px;

    display:flex;
    justify-content: center;
    align-items: center;
}

.audienceSearch input {
    width: 288px;
    height: 48px;

    /*left: 24px;
    width: 24px;*/    

    background: var(--back2);

    border: 1px solid rgba(116, 137, 150, 0.15);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* Firefox 4+, Opera, Chrome */

    box-sizing: border-box;
    border-radius: 6px;
}

.inputSearch {
    padding-right: 46,83px;
    padding-left: 15px;
}


.audienceSearch > ::-webkit-input-placeholder { /* Edge */
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    /* identical to box height */
    color: rgba(116, 137, 150, 0.7);
}
  
.audienceSearch > :-ms-input-placeholder { /* Internet Explorer */
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    /* identical to box height */
    color: rgba(116, 137, 150, 0.7);
}
  
.audienceSearch > ::placeholder {
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    /* identical to box height */
    color: rgba(116, 137, 150, 0.7);
}

.audienceSearch input:focus {
    outline: none;

    padding-right: 46,83px;
    padding-left: 15px;

    color: var(--mainColor);

    border: 1px solid var(--thirdColor);
    box-sizing: border-box;
    border-radius: 6px;
}

.audienceSearch > .icoSearch {
    position: absolute;
    right: 40.83px;
	top: 50%;
	transform: translateY(-50%);
}

/* -------------------- dropdown / lista --------------- */

.audienceList {
    height: 167px;
    overflow-y: scroll; /*scroll*/
    overflow-x: hidden;

    padding-top: 24px;
    margin-right: 5px;
}

.mediumDropdown-content > ::-webkit-scrollbar {
    width: 6px;    
}

.mediumDropdown-content > ::-webkit-scrollbar-thumb {
    width: 6px;
    background: #D5D9DE;
    border-radius: 100px;    
}

.mediumDropdown-content > ::-webkit-scrollbar-track {
    width: 6px;
    background: var(--white);
    border-radius: 100px;
}   

.lines {
    /* BORDER 2 */
    background-color: var(--background);

    border: 1px solid var(--background);
    box-sizing: border-box;

    margin: 0;
}

.contentSelect {
    grid-area: 2 / 2;
    width: 400px;
    margin-left: 24px;

    display:flex;
    flex-wrap: wrap;

    align-items: center;
}

.mediumDrop {
    grid-area: 2 / 3;
    width: 48px;

    cursor: pointer;

    display:flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
}


input[type=checkbox] {

    cursor: pointer;

    width: 13px;
    height: 13px;

    border: 1px solid var(--thirdColor);
    box-sizing: border-box;
    border-radius: 2px;
}

.audienceSelectAll {
    margin: 0px 24px 24px 24px;

    display:flex;    
    align-items: center;

    /*margin: 8px;*/

    /* H5/H5 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* Third color */
    color: var(--thirdColor);
}

.audienceSelectAll label {
    margin-left: 8px;
}

.optionDrop {
    width: 336px;
    min-height: 135px;
    
    display:flex;
    flex-direction: column;
}

.audienceAdd {
    height: 39px;

    margin-left: 24px;

    display:flex;
    align-items: center;
    flex-direction: row;    
}

.audienceAdd > .ico {
    margin-right: 7px;
}


.audienceAdd > H5{
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* AUXILIAR COLOR 1 */

    color: var(--fourthColor);
}