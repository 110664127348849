
  .cardHome {
    height: 208px;
    width: 200px;
  }
  .contCard {
    border-radius: 15px;
    height: 208px;
    width: 200px;
    background-color: var(--white);
  }
  .textFirst {
    text-align: left;
    vertical-align: top;
    font-size: 18px;
    font-family: Mulish;
    line-height: auto;
    color: var(--mainColor);
  }
  .textSecond {
    text-align: left;
    vertical-align: top;
    font-size: 14px;
    font-family: Mulish;
    line-height: auto;
    color: #748996;
  }
  .img-3 {
    height: 42px;
    width: 43px;
    background-color: var(--white);
  }
  