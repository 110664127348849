.controlPreview {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;

    padding: 8px;
    margin: 8px;
}

.tagPreview {
    background: var(--white);
    height: 30px;
    /*width: 90px;*/

    padding: 4px 12px;
    /*padding-left: 8px;
    padding-right: 8px;*/

    border: 1px solid #E4EAEE;
    border-radius: 6px;

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    
    /* MAIN COLOR */
    color: var(--mainColor);
}