.containerNewsletterAction {

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;  
    /*justify-content: center;*/

    gap: 40px;

    position: absolute;
    /*width: 212px;*/
    height: 24px;
    
    right: 28px; 
    top: 22px; 
}

.contentNewsletterAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; 
}

.containerNewsletterActionText {

    margin-left: 8px;

    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */


    /* Third color */

    color: #748996;
}