@import url('https://fonts.googleapis.com/css2?family=Mulish');

.TitleManageEmail {
    position: absolute;
    height: 38px;
    left: 143px;
    top: 82px;
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    min-width: 1080px;
    width: 80%;
}

.TitleManageEmail > .ico {
    position: absolute;
}

.TitleManageEmail H3 {
    margin-left: 30px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--mainColor);
}
.TitleEmail {
    position: absolute;
    height: 38px;
    left: 143px;
    top: 82px;
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    min-width: 730px;
    width: 80%;
}

.TitleEmail > .ico {
    position: absolute;
}

.TitleEmail H3 {
    margin-left: 30px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--mainColor);
}

.titleArrow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pointerCursor {
  cursor: pointer;
}

.mail {    
    position: absolute;
    width: calc(85% - 40px);
    /*min-height: 722px;*/
    height: auto;
    left: 135px;
    top: 144px;
}

.mailContent {
  position: relative;
    min-height: 722px;
    height: auto;
    background: var(--white);
    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 0px 0px 15px 15px;
}

.previewEmail {
    position: absolute;
    min-width: 700px;
    width: calc(80% - 40px);
    left: 140px;
    top: 144px;
    height: auto;
}

.containerRowsEmail {
    position: sticky;
    min-height: calc(100vh - 200px);
    height: auto;
    background: var(--white);
    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px;
    width: 100%;
    padding: 20px;
    min-width: 1000px;

}

.containerRowsDonations {
    position: relative;
    min-height: calc(100vh - 100px);
    height: auto;
    background: var(--white);
    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px;
    padding: 20px;
    min-width: 85vw; 
}

.containerRows {
    position: relative;
    min-height: calc(100vh - 100px);
    height: auto;
    background: var(--white);
    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px;
    width: 100%;
    padding: 20px;
    min-width: 1000px;
}

.containerRows3 {
    background-color: transparent;
    position: relative;
    /* min-height: 722px; */
    height: auto;
    border-radius: 15px;
    width: 100%;
    padding-left: 35px;
}

.firstRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.secondRow {
    display: flex;
    flex-direction: column;
}

.spaceIntoInputsRigth {
    margin-right: 5%;
    width: 50%;
}

.spaceIntoInputsLeft {
    margin-left: 5%;
    width: 50%;
}

.containerHtml {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: -8px 16px 50px rgb(112 144 176 / 10%);
    border-radius: 15px;
    min-height: 70vh;
}

#igr3,
#igr3-2,
#ikt8,
#ivxq,
#iek1,
#ix6w,
#ivxq-2,
#ix55h,
#i0cl,
#iq3m,
#i5a9,
#i3bf,
#ivxq-3,
#ivxq-4,
#ivxq-5,
#iy8e6,
#igr3-3,
#igr3-4,
#igr3-5,
#iek1-2 {
    border: 1px dotted gray;
}

.spaceIntoRows {
    margin-top: 18px;
}

.spaceIntoCheckbox {
    margin-top: 5px;
    margin-bottom: 5px;
}

.containerRowsAudience {
    position: relative;
    height: calc(100vh - 200px);
    background: var(--white);
    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px;
    width: 100%;
    padding: 20px;
    padding-left: 50px;
}

.spaceLeft {
    padding-left: 30px;
}

.headerTable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60vw;
}

.headerTableTh {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 1.2em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.containerTable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60vw;
}

.tdTable {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.spaceLeftTable {
    margin-left: 25px;
}

.rowAddUsers {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* -------------------------------- */

.buttonTooltip .tooltiptext {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 16px;
    background-color: #00A4BD;
    border-radius: 4px;
    font-family: 'Mulish';
    font-size: 14px;
    color: var(--white);
    position: absolute;
    top: 50px;
    transition: opacity 0.3s;
    min-width: 120px;
    width: auto;
}
  
.buttonTooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    right: 60px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #00A4BD transparent;
}
  
.buttonTooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
