.actionText {
    display:flex;
    flex-direction: row;
    column-gap: 16px;
    margin-top: 8px;
}

.fontStyle {
    width: 32px;
    height: 32px;

    cursor: pointer;

    /*font-weigth: bold;*/

    display:flex;
    justify-content: center;
    align-items: center;

    margin: 0 4px;

    background: #F5F5FD;
    border-radius: 5.25926px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;

    /* Third color */
    color: #748996;
}

.editText {
    
    resize: none;
    /*height: auto;*/
    min-height: 104px;

    /*overflow: hidden;*/

    padding-left: 16px;
    padding-right: 16px;

    background: #F5F5FD;
    border-radius: 6px;
    border-color: transparent;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    /* Third color */
    color: #748996;
}

.editText input:focus {

    background: #F5F5FD;
    border-radius: 6px;
    border-color: transparent;

}

.rdw-emoji-modal {
    overflow-y: scroll; /*scroll*/
    overflow-x: hidden; 
    
    border: 1px solid #EAEEEF;
    box-sizing: border-box;
    border-radius: 6px;
}


.rdw-emoji-modal::-webkit-scrollbar {
    width: 6px;    
}

.rdw-emoji-modal::-webkit-scrollbar-thumb {
    width: 6px;
    background: #D5D9DE;
    border-radius: 100px;    
}

.rdw-emoji-modal::-webkit-scrollbar-track {
    width: 6px;
    background: var(--white);
    border-radius: 100px;
}  