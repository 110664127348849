@import url('https://fonts.googleapis.com/css2?family=Mulish');


.Title {
    position: absolute;
    /*min-width: 197px;*/
    height: 38px;
    left: 133px;
    top: 82px;

    display: flex;
    align-items: center;
}

.Title > .ico {
    position: absolute;
}

.Title H3 {

    margin-left: 30px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    color: var(--mainColor);
}

.pointerCursor {
  cursor: pointer;
}

.newsletter {    
    position: absolute;
    width: 536px;
    /*min-height: 722px;*/
    height: auto;
    left: 135px;
    top: 144px;
}

.newsletterContent {
  position: relative;
    min-height: 412px;
    height: auto;

    min-width: 1096px;
    width: auto;

    background: var(--white);

    box-shadow: -8px 16px 50px rgba(112, 144, 176, 0.1);
    border-radius: 15px 15px 15px 15px;
}


/* ------------------------------------------------ */
 
.newsletterContentTab {
  position: absolute;
  width: 984px;

  left: 56px;
  top: 56px;  
}

.newsletterContentSection {

  box-sizing: border-box;
  
  /*position: absolute;*/
  width: 932px;
  height: 48px;

  /* BORDER 2 */
  border: 1px solid #EFF1F8;
  border-radius: 0px 6px 6px 0px;

  /* H4/H4 MEDIUM */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  /* MAIN COLOR */
  color: var(--mainColor);
}

.newsletterContentSection::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 48px;
  left: -4px;
  top: 0px;

  /* AUX COLOR/AUX COLO */
  background: #8188CE;
  border-radius: 6px 0px 0px 6px;
}

.newsletterContentPanel {

  /*display: none;*/
  overflow: hidden;

  /*position: absolute;*/
  width: 931px;
  height: 54.5px;
  left: 28.5px;
  top: 49px;

  /* BORDER 2 */
  border: 1px solid #EFF1F8;
  border-radius: 6px;
}

/* --------------------------- */

.newsletterContentTabRecord {

  padding: 40px 32px; 
    
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 24px 24px; 

}