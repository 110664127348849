//New design color styles

@import url("https://fonts.googleapis.com/css2?family=Mulish");
$font-family: "Mulish", sans-serif;

/* GAMA CROMATICA PRINCIPAL */
:root {
  --mainColor: #183e55;
  --secondColor: #ff8614;
  --thirdColor: #748996;
  --fourthColor: #00a4bd;

  --background: #fafafb;

  --linesBorderCard: #c4cace;

  --auxiliarColor1: #8188ce;
  --auxiliarColor2: #ffb7ac;
  --auxiliarColor3: #ffcb96;

  --border2: #eff1f8;
  --back2: #f5f5fd;
  --white: #ffffff;

  --shadow: rgba(112, 144, 176, 0.08);

  --hoverButton: #ffba78;

  /* COLORES SISTEMA */
  --systemSuccess: #07bfa7;
  --systemWarning: #f5c26b;
  --systemError: #f06855;
}

.h1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;

  color: #000000;
}

.h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;

  color: #000000;
}

.pointerCursor {
  cursor: pointer;
}

.pointerCursor:hover {
  filter: opacity(50%);
}

.hover:hover {
  filter: opacity(50%);
}

//General utils --------------------- OLD

//General utils

// Colors Palet
$Primary1000: #cf6400;
$Primary900: #f17400;
$Primary800: #ff8614;
$Primary700: #ff9532;
$Primary600: #ffa44f;
$Primary500: #ffb36c;
$Primary400: #ffc28a;
$Primary300: #ffd2a7;
$Primary200: #ffe1c4;
$Primary100: #fff0e2;
$Primary50: #fbf6ff;
$Primary0: #fdfcff;

$Secondary1000: #6004c3;
$Secondary900: #7005e3;
$Secondary800: #8211fa;
$Secondary700: #902dfb;
$Secondary600: #a04bfb;
$Secondary500: #b069fc;
$Secondary400: #c087fd;
$Secondary300: #d0a5fd;
$Secondary200: #dfc3fe;
$Secondary100: #efe1fe;

$Success1000: #0d8145;
$Success900: #0f9650;
$Success800: #11ab5b;
$Success700: #15d06f;
$Success600: #24e983;
$Success500: #48ed98;
$Success400: #6df1ac;
$Success300: #91f4c1;
$Success200: #b6f8d6;
$Success100: #dafbea;
$Error1000: #c00b3e;
$Error900: #e00d49;
$Error800: #f21b58;
$Error700: #f3396e;
$Error600: #f55582;
$Error500: #f77197;
$Error400: #f88eac;
$Error300: #faaac1;
$Error200: #fcc6d5;
$Error100: #fde3ea;

$Warning1000: #d59601;
$Warning900: #f9ae01;
$Warning800: #febc20;
$Warning700: #fec43c;
$Warning600: #fecc58;
$Warning500: #fed573;
$Warning400: #fedd8f;
$Warning300: #ffe6ab;
$Warning200: #ffeec7;
$Warning100: #fff7e3;

$BlackBase: #0a1128;
$BlackHigh: #2a3044;
$BlackHalf: #6c707e;
$BlackLow: #a2a4ad;
$WhiteBase: #ffffff;
$WhiteHigh: #ffffff;
$WhiteHalf: #bfc1c7;
$WhiteLow: #676b79;

$Gray1: #c2c3c9;
$Gray2: #e1e2e5;
$Gray3: #f1f1f2;
$Gray4: #fafafb;
$Gray5: #f5f3f8;

//Font styles
$FontFamily1: "Quicksand-Bold";
$FontFamily2: "DMSans-Bold";
$FontFamily3: "DMSans-Regular";
$FontFamily4: "DMSans-Medium";

$FontSize56: 56px;
$FontSize49: 49px;
$FontSize39: 39px;
$FontSize31: 31px;
$FontSize25: 25px;
$FontSize22: 22px;
$FontSize20: 20px;
$FontSize16: 16px;
$FontSize14: 14px;
$FontSize12: 12px;
$FontSize10: 10px;
$FontSize18: 18px;

$LineHeight68: 68px;
$LineHeight37: 37px;
$LineHeight32: 32px;
$LineHeight24: 24px;
$LineHeight22: 22px;
$LineHeight53: 53px;
$LineHeight16: 16px;
$LineHeight18: 18px;
$LineHeight32: 32px;
$LineHeight244: 44px;
$LineHeight28: 28px;
$LineHeight157: 1.57px;

$LetterSpacing0: 0px;
$LetterSpacing015: 0.15px;
$LetterSpacing02: 0.2px;
$LetterSpacing05: 0.5px;
$LetterSpacing13: 1.3px;
$LetterSpacing019: 0.19px;
$LetterSpacing025: 0.25px;

//Elevations (shadows)
$ElevationBlur4: 4px;
$ElevationBlur8: 8px;
$ElevationBlur12: 12px;
$ElevationBlur20: 20px;
$ElevationBlur24: 24px;
$ElevationBlur32: 32px;

//Borders
$BorderRadious4: 4px;
$BorderRadious8: 8px;
$BorderRadious16: 16px;
$BorderRadious88: 28px;
$BorderRadious50: 50px;

$LoginBackgroundImage: "../../../assets/images/bgLoginHubUhoo.jpg";
