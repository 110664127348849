body {
    background-color: var(--white);
  }

.login{

    background: var(--white);

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
}

.containerLogin {

    background-color: var(--white);
    display: flex;
    justify-content: center;    
    position: absolute;
    width: 425px;
    height: 535px;
}

.containerLoginNotAllowed {
    background-color: var(--white);
    display: flex;
    justify-content: center;    
    position: absolute;
    width: 425px;
    height: 535px;
    cursor: not-allowed;
}

.logoLogin {
    position: absolute;
    width: 100.18px;
    height: 48px;
    top: 0px; /*65-65*/
}

.titleLogin{
    position: absolute;
    /*width: 227px;*/
    height: 40px;
    top: 102px; /*167-65*/

    /* H1 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    /* MAIN COLOR */
    color: var(--mainColor);
}

.subTitleLogin{
    position: absolute;
    top: 165px; /*221-65*/

    /* H4/H4 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    /* or 137% */

    text-align: center;

    /* MAIN COLOR */

    color: var(--mainColor);
}

.actionLogin {
    position: absolute;
    width: 425px;
    height: 180px;

    display: flex;
    align-items: center;
    flex-direction: column;

    gap: 16px;

    top: 223px; /*288-65*/
}

.inputLogin {
    display: flex;
    align-items: center;
}

.inputLogin > .ico {
    position: absolute;
    left: 16px;
}

.inputLogin > input{
    width: 425px;
    height: 40px;

    padding-left:40px;
    box-sizing: border-box;

    /* BORDER 2 */
    background: #EFF1F8;
    border-radius: 6px;
    border-style: none;
}

.inputLogin {
    /* H4/H4 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

.inputLogin > input::placeholder{

    /* H4/H4 */
     font-family: 'Mulish';
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 25px;
     /* identical to box height, or 137% */
 
     /* LINES BORDER CARD */
     color: #C4CACE;
 }

 .actionLoginButtonNotAllowed > button{

    width: 425px;
    height: 40px;

    cursor: pointer;

    /* fourthColor */
    background: #00A4BD;
    border-radius: 6px;
    border-style: none;

    /* H4/H4 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    /* BACKGROUND */
    color: #FAFAFB;
    cursor: not-allowed;
}

.actionLoginButtonNotAllowed > button:hover{
    background: rgba(0, 164, 189, 0.5);
}

.actionLoginButton > button{

    width: 425px;
    height: 40px;

    cursor: pointer;

    /* fourthColor */
    background: #00A4BD;
    border-radius: 6px;
    border-style: none;

    /* H4/H4 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    /* BACKGROUND */
    color: #FAFAFB;
}

.actionLoginButton > button:hover{
    background: rgba(0, 164, 189, 0.5);
}

.rememberMe {
    /*display: flex;*/

    margin-left: auto;
    margin-right: 0%;

    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    
    /* MAIN COLOR */
    color: var(--mainColor);
}

.optionActionLogin {

    display: flex;

    /* H6/H6 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    
    /* MAIN COLOR */
    color: var(--mainColor);
}

.optionActionLogin > .left {
    position: absolute;    
    left: 0px;    
    cursor: pointer;
} 

.optionActionLogin > .left > a:link, a:visited, a:active {
    text-decoration:none;
    color: var(--mainColor);
} 

.optionActionLogin > .right {
    position: absolute;
    right: 0px; 
    cursor: pointer;  
} 

.optionsLogin{
    
    position: absolute;

    top: 452px; /*452 - 65*/

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 18px;
}

.textOptionsLogin {
    /* H4/H4 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    
    /* MAIN COLOR */
    color: var(--mainColor);
}

.buttonsOptionsLogin {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 24px;    
}

.buttonsOptionsLogin > div {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 88px;
    height: 40px;

    /* BORDER 2 */

    background: #EFF1F8;
    border-radius: 6px;    
}



