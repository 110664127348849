
.tagSelect {
    
    height: 32px;
    margin: 4px;

    display: flex;
    align-items: center;

    background: #EBEBEB;   /*var(--white);*/
    border-radius: 100px;
}

.avatarTag {
    grid-area: avatar;
    padding: 4px 8px;
    left: 8px;
}

.textTag {
    grid-area: texto;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    
    color: var(--mainColor);
}

.close {
    width: 8px;
    height: 8px;

    margin-right: 16px;
    margin-left: 14px;

    cursor: pointer;

    display:flex;
    justify-content: right;
    align-items: center;
}

.close:hover {
    stroke: green;
    fill: green;
}



h5 {
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* MAIN COLOR */
    color: var(--mainColor);
}
  