@font-face {
  font-family: "DMSans-Bold";
  src: url("./DMSans-Bold.ttf");
}
@font-face {
  font-family: "DMSans-Medium";
  src: url("./DMSans-Medium.ttf");
}
@font-face {
  font-family: "DMSans-Regular";
  src: url("./DMSans-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("./Poppins-Black.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./Poppins-ExtraBold.ttf");
}