@import url('https://fonts.googleapis.com/css2?family=Mulish');

.Title {
    position: absolute;
    /*min-width: 197px;*/
    height: 38px;
    left: 133px;
    top: 82px;

    display: flex;
    align-items: center;
}

.Title > .ico {
    position: absolute;
}

.Title H3 {

    margin-left: 30px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    color: var(--mainColor);
}

.pointerCursor {
  cursor: pointer;
}

.libraryContent {
  position: absolute;
  left: 125px;
  top: 130px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 24px 24px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
