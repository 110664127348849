@import "../../assets/styles/index.scss";


.inlineIconWithTextBreadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.principalBreadcrumb {
  margin-left: 0.5%;
  font-size: $FontSize25 !important;
  line-height: $LineHeight37 !important;
  letter-spacing: $LetterSpacing015 !important;
  font-family: $FontFamily2 !important;
  color: $BlackBase;
}

.principalBreadcrumbWithOutIcon {
  margin-left: 0.5%;
  font-size: $FontSize20 !important;
  line-height: $LineHeight37 !important;
  letter-spacing: $LetterSpacing015 !important;
  font-family: $FontFamily2 !important;
  color: $BlackBase;
  padding-left: 15px;
}

.otherLayoutBreadcrumbs {
  color: $WhiteHalf !important;
  font-size: $FontSize14 !important;
  line-height: $LineHeight22 !important;
  letter-spacing: $LetterSpacing015 !important;
  font-family: $FontFamily3 !important;
}

.iconBreadcrumb{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  color: $Primary700;
  padding-right: 15px;
  padding-left: 15px;
}

.MuiBreadcrumbs-separator {
  color: $WhiteHalf !important;
}

.inlineArrowWithBreadcrumb {
  margin-left: 2%;
  margin-top: 2%;
  display: flex;
  align-items: center;
}


.iconArrowLeftSections {
  width: 20px;
  color: $Primary600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nameGroupSubpage {
  margin-left: 0.5%;
  font-size: $FontSize25 !important;
  line-height: $LineHeight37 !important;
  letter-spacing: $LetterSpacing015 !important;
  font-family: $FontFamily2 !important;
  color: $BlackBase;
  padding-left: 15px;
}