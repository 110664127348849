@import url('https://fonts.googleapis.com/css2?family=Mulish');

.Content {
    left: 135px;
    width: 100%;
}

.headline {
    position: absolute;
    top: 80px;
    left: 133px;
}

.dataTitle {
    position: absolute;
    width: 302px;
    height: 40px;
    
    top: 0px;
}

.dataTitle H1 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: var(--mainColor);
}

.dataSubTitle {
    position: absolute;
    width: 177px;
    height: 20px;

    top: 40px;
}

.dataSubTitle H3 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #748996;
}

.previewPayments {
    position: absolute;
    min-width: 700px;
    width: auto;
    left: 130px;
    top: 162px;
    height: auto;
}

.containerButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: flex-end;
    margin-top: 15px;
    left: 56%;
    position: absolute;
}

.containerPaginationDonation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: flex-end;
    margin-top: -10px;
    left: 56%;
    position: absolute;
}

.containerButtonCreate {
    display: flex;
    justify-content: flex-end;
}

.buttonTable {
    font-size: 17px;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 18px;
    vertical-align: middle;
    cursor: pointer;
    border: 2px solid transparent;
    background-color: var(--mainColor);;
    color: #ffffff;
    margin: 5px;
}

.buttonTableIndicator {
    font-size: 17px;
    border-radius: 5px;
    border: 2px solid gray;
    display: inline-block;
    padding: 6px 18px;
    vertical-align: middle;
    background-color: gray;
    color: #ffffff;
    margin: 5px;
}

#payments__table {
    font-family: 'Mulish';
    border-collapse: collapse;
    width: 97%;
    border-radius: 8px;
    margin: 0px 1.1%;
}


#payments__table {
    border: 1px solid #ddd;
    padding: 2px;
    text-align: center;
    padding: 5px;
    /* min-height: 70vh; */
}

#payments__table tr:nth-child(even){
    background-color: #f2f2f2;
}

#payments__table tr:hover {
    background-color: #ddd;
}

#payments__table td, #payments__table th {
    border: 1px solid #ddd;
    padding: 2px;
    padding: 5px;
    width: 150px;
    max-width: 160px;
    overflow: hidden;
}

#payments__table th {
    font-size: 13px;
    font-family: 'Mulish';
    color: var(--mainColor);
    padding: 5px;
}

#searchText {
    font-family: 'Mulish';
    width: 163px;
    font-size: 16px;
    padding: 12px 20px 12px 14px;
    border: 1px solid #c4c4c4;
    margin: 0px 5px;
    border-radius: 5px;
}

#searchDate {
    font-family: 'Mulish';
    width: 204px;
    font-size: 16px;
    padding: 12px 0px 12px 15px;
    border: 1px solid #c4c4c4;
    margin: 0px 6px;
    color: gray;
    border-radius: 5px;
}

#paymentStatus, #paymentPlatform {
    font-family: 'Mulish';
    width: 240px;
    font-size: 16px;
    padding: 12px 20px 12px 10px;
    border: 1px solid #c4c4c4;
    margin: 0px 5px;
    border-radius: 5px;
}

.textDefaultCommunity {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #748996;
    margin: 0px;
    padding: 0px 0px 12px 5px;
}

.buttonAplyFilterUsers, .buttonAplyFilterUsers2 {
    height: 40px;
    border-radius: 6px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FAFAFB;
    background: var(--secondColor);
    border: 1px solid var(--secondColor);
}

#audienceSelect, #rolSelect {
    width: 225px;
    font-size: 16px;
    padding: 5px 20px 5px 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 35px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #748996;
}

.containerFilters {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100px;
}

.containerFilters p{
    font-size: 14px;
    line-height: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    color:  white;
    padding: 15px;
}

.containerGeneral {
    display: flex;
    padding: 12px;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px 0px;
}

.containerFiltersGeneral {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filters {
    padding-left: 10px;
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    width: 1300px;
}

.center {
    display: flex;
    justify-content: center;
}

.iconFilter {
    color: white;
    height: 20px;
    width: 20px;
}

/* ----------------- */

.dNone {
    display: none;
}

.containerGeneralInputs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
}

.containerFiltersGeneralInline {
    display: flex;
    width: 90%;
    flex-wrap: nowrap;
    flex-direction: column;
}

.containerGeneralButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.containerButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 40px;
}

.containerButtonsSearch {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.containerButtonAction {
    display: flex;
    justify-content: center;
    align-items: center;
}


.pBack {
    cursor: pointer;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    position: absolute;
    border: 0.428571px solid var(--thirdColor);
    border-radius: 2.57143px;
    right: 100px;
}

.textDataPagination {
    position: absolute;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    font-size: .79vw;
    color: #748996;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -20px;
    width: 50px;
}

.pForward {
    cursor: pointer;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    position: absolute;
    border: 0.428571px solid var(--thirdColor);
    border-radius: 2.57143px;
    right: 25px;
}

.titleInputGeneral {
    font-size: 22px;
    line-height: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    color: var(--mainColor);
    padding: 8px 6px;
}

.iconButtonFilter {
    height: 25px;
}

.firstRowFiter {
    display: flex;
}

.secondRowFilter {
    display: flex;
}

.containerModalQst {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    overflow: hidden;
    align-items: stretch;
    width: 938px;
    height: 592px;
    background: var(--background);
    border: 1px solid var(--border2);
    box-shadow: -8px 4px 45px var(--shadow);
    border-radius: 6px;
}

.containerInfoModalQst {
    flex: 1 1;
    position: relative;
    background: rgba(196, 202, 206, 0.2);
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}