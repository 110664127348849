.pictureAttachments {
    
    position: relative;

    width: 147px;
    height: 144px;
    
    background: var(--white);
    /*background-color: #EBEBEB; /* for Test */
    
    /*mix-blend-mode: normal;*/
    
    /* DROP SHADOW */
    box-shadow: -8px 4px 30px rgba(112, 144, 176, 0.1);
    border-radius: 15px;
}

.fileAttachments {

    position: absolute;
    
    width: 135.24px;
    height: 83.57px;  

    margin-top: 5.18px;
    margin-left: 5.88px;
    margin-right: 5.88px;

    

    /* background-color: #FFCB96; /* for Test */

    border-radius: 8px;
}

.fileAttachments > img, .fileAttachments > video {

    width: 135.24px;
    height: 83.57px; 

    object-fit: cover;
    
    border-radius: 8px;
}

.closeAttachments {

    position: absolute;

    cursor: pointer;
    
    border-radius: 50px;
    /*z-index: 1;*/

    width: 17.64px;
    height: 17.75px;

    left: 79.5%;
    right: 8.5%;
    top: 8.33%;
    bottom: 79.17%;


    /* BACKGROUND */
    background: #FAFAFB;

    display: flex;
    justify-content: center;
    align-items: center;
}

.nameAttachments {

    position: absolute;
    left: 8%;
    /*right: 49.53%;*/
    top: 67.75%;
    bottom: 18.26%;

    /*margin-left: 11.76px;*/

    max-width: 129.36px;

    overflow-x: hidden;
    overflow-y: hidden;

    /* H5/H5 MEDIUM */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    /* MAIN COLOR */
    color: var(--mainColor);
}

.dateAttachments {

    position: absolute;
    left: 8%;
    /*right: 35.15%;*/
    top: 81.52%;
    bottom: 7.99%;
    /*margin-left: 11.76px;*/

    max-width: 129.36px;

    overflow-x: hidden;
    overflow-y: hidden;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    color: #748996;
}

.module {
    width: 100px;
    margin: 0 0 1em 0;
    overflow: hidden;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}