.paginationContainer {
  position: absolute;
  width: 114px;
  /* height: 24px; */

  /* left: 50%; */
  margin-left: -57px;
  /*right: 50%;
    margin-right: -57px;*/

  bottom: -50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationContainer2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationText {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  /*font-size: 0.702rem;*/
  line-height: 1rem;

  font-size: 0.790vw;  

  display: flex;
  align-items: center;
  text-align: center;

  /* Third color */

  color: #748996;
}

.paginationActions {
  cursor: pointer;

  box-sizing: border-box;
  width: 24px;
  height: 24px;

  position: absolute;

  /* Third color */
  border: 0.428571px solid var(--thirdColor);
  border-radius: 2.57143px;

  display: flex;
  align-items: center;
  text-align: center;
}

.paginationICO {
  padding: 4.5px;
}

.paginationBack {
  left: 0%;
}

.paginationForward {
  right: 0%;
}

@media only screen and (max-width: 1366px) {
  .paginationText {
    font-size: 12px;
    line-height: 15px;
  }
}
