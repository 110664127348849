.post {    
    box-sizing: border-box;

    width: 360px;
    height: auto;

    padding-bottom: 16px;

    /* WHITE */
    background: var(--white);
    
    /* BORDER 2 */
    border: 1px solid #E4EAEE;
}

.headerPost {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;
}

.actionPostDelete {
    margin: 8px 24px;
}

.userPost {

    margin-left: 28px;
    margin-top: 18px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.imgUserPost{
    /*position: absolute;*/

    background-color: var(--auxiliarColor2);

    width: 32px;
    height: 32px;

    border-radius: 50%;

    /*left: 7.65%;
    right: 83.61%;
    top: 5.98%;
    bottom: 83.39%;*/
}

.imgUserPost > img {

    width: 32px;
    height: 32px;

    object-fit: cover; /*contain*/
    
    border-radius: 50%;
}

.captionUserPost {
    margin-left: 8px;
    /*position: absolute;*/
    /*width: 113px;
    height: 35.13px;
    /*left: 68px;
    top: 16px;*/
}

.nameUserPost{
    /*position: absolute;
    left: 18.58%;
    right: 50.55%;
    top: 5.32%;
    bottom: 88.04%;*/

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */


    /* MAIN COLOR */

    color: var(--mainColor);
}

.datePost {
    /*position: absolute;
    left: 18.93%;
    right: 62.49%;
    top: 11.67%;
    bottom: 83.01%;*/

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    /* Third color */

    color: #748996;
}

.contentPost {

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    margin-top: 10px;
    margin-bottom: 8px;
    margin-left: 28px;
    margin-right: 28px;
    
    width: 312px;
    height: auto;
}

.imgContentPost {
    width: 312px;
    overflow: hidden;        
    align-items: center;
}

.slides {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    align-items: center;
}

.slides > img {
    /*object-fit: cover; /*contain*/    
    /*width: 200px;*/

    scroll-snap-align: center;
}

.slides img {
    object-fit: cover;
    width: 312px;
    border-radius: 4px;
}

.slides > video {
    /*object-fit: cover; /*contain*/    
    /*width: 200px;*/

    scroll-snap-align: center;
}

.slides video {
    object-fit: cover;
    width: 312px;
    border-radius: 4px;
}

.slides::-webkit-scrollbar-thumb {
    /*background-color: yellow;*/
    background-clip: padding-box;
    border: 0.05em solid #eeeeee;
  }

  .slides::-webkit-scrollbar-track {
    background-color: green;
  }

.slides::-webkit-scrollbar-button:single-button {
    /*background-color: red;*/
    /*display: block;*/
    border-style: solid;
    height: 13px;
    width: 16px;
  }

/* slides
.imgContentPost {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    align-items: center;
}

.imgContentPost > img {
    /*object-fit: cover; /*contain*/    
    /*width: 200px;

    scroll-snap-align: start;
}

.imgContentPost img {
    object-fit: cover;
    width: 200px;
    border-radius: 4px;
}

.imgContentPost::-webkit-scrollbar {
    display: none;
}*/


.txtContentPost {
    /*position: absolute;
    left: 7.65%;
    right: 7.1%;
    top: 20.6%;
    bottom: 47.84%;*/

    word-wrap: break-word;

    margin-left: auto;
    margin-right: auto;

    width: 300px;

    text-align: justify;

    /* BODY */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    /* MAIN COLOR */
    color: var(--mainColor);
}

.txtTitlePost {

    /*text-align: center;*/
    text-transform: uppercase;

    width: 300px;
    
    /* BODY */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    /* MAIN COLOR */
    color: var(--mainColor);
}

.interactionsPost {    
    
    width: 300px;

    padding: 4px;
    
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    gap: 24px;    
}

.commentsPost {
    display: none;
}

.show { display: block; }

.toCommentPost {
    
    width: 310px;
    height: 48px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.toCommentPost > input[type=text] {
    box-sizing: border-box;

    width: 310px;
    min-height: 48px;

    /* Third color */
    border: 1px solid #748996;
    border-radius: 10px; 
    
    padding: 14px;

    box-sizing: border-box;

    
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* Third color */
    color: #748996; 
}

@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

.postError {
    font-family: 'Inconsolata', monospace;
    font-size: 12px;
    color: var(--systemError); 
}

.documentPreviePost {
    max-width: 200px;
    height: auto;
    cursor: pointer;
}