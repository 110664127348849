
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital@1&display=swap');

.contentWhatsPreview {
    background-image: url("../../../assets/images/WhatsPreview.png");
    background-color: var(--white);
    height: 720px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 15px;
    margin: auto;
    vertical-align: middle;
    clip-path: inset(0 0 50px 0);
    clip-path: border-box;
}

.contentWhatsPreviewura {
    background-image: url("../../../assets/images/WhatsPreviewAura.png");
    background-color: var(--white);
    height: 720px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 15px;
    margin: auto;
    vertical-align: middle;
    clip-path: inset(0 0 50px 0);
    clip-path: border-box;
}

.WhatsChat {
    overflow: hidden;

    position: relative;
    margin: auto;
    vertical-align: bottom;    

    top: 82px;
    
    height: 440px;
    width: 270px;
}

.WhatsChatContent {
    
    position:absolute;
    padding: 5px;
    vertical-align: middle;
    width: 260px;    
    bottom:0px;
}

.Chat {
    background-color: white; 
    border-radius: 5px;

    word-wrap: break-word;
    height:auto;

    padding: 5px;
     
    margin-left: 15px;
    width: 80%;
}

.Chat::after {
    content: "";
    position: absolute;

    bottom: 10px;
    left: 9px;
    
    border-width: 6px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}

.textChat {
    word-wrap: break-word;
    height:auto;

    padding: 5px 10px;

    font-family: 'Arimo', sans-serif;
}

.imageChat {
    width: 208px;
    border-radius: 6px;
}

