.contentUsers {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.contentUsersSelectNoFilter {
    display: flex;
    align-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    height: 30px;
    width: 95%;
    margin-left: 46px;
    margin-right: 46px;
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #748996;
}

.contentUsersSelect {
    display: flex;
    align-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    height: 60px;
    width: 95%;
    margin-left: 46px;
    margin-right: 46px;
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #748996;
}

.contentUsersSelectWithFilter {
    display: flex;
    align-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    height: 100px;
    width: 92.5%;
    margin-left: 46px;
    margin-right: 46px;
    /* H5/H5 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #748996;
}

.contentUsersCards {
    padding: 10px 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 24px;
    width: calc(100% - 64px);
    height: calc(100% - 70px);
    justify-content: space-around;
    background-color: white;
}

/* ----- */

.counterText {
    /* H6/H6 */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    /* Third color */
    color: #748996;

}

.actions {

    display: none;

    /*background-color: aqua;*/

    margin-top: 12px;
    margin-left: 48px;

    height: 40px;

    min-width: 50px;
}

.containerInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.titleInput {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    color: var(--mainColor);;
    padding: 8px 6px;

}

.containerInputsCheckbox {
    margin-bottom: 5px;
}

.spaceIntoComponents {
    display: flex;
    justify-content: space-between;
    width: 97%;
    align-items: center;
}
